import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../../context/ContextApi";
import { AnimationFileExtract, PreloaderDocument } from "../../utils/images";
import { documentFileExtract } from "../../api/Postaction";
import { documentJournalPaperBack } from "../../api/GetActions";
import { documentProtocolDocFileExtract } from "../../api/Postaction";
import { toast } from "react-toastify"; 
import "./documentFileExtract.css";

const DocumentFileExtract = () => {
  const {
    retrivedInfo,
    setRetrivedInfo,
    contenttype,
    convertedMarkdown,
    extractedFields,
    setStructureHeading,
    contentIDFileExtract,
    structureHeading,
    journalPaperTitle,
    setFilePath
  } = useContext(MyContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

   const handleInputChange = (e, key) => {
     const textarea = e.target;

     textarea.style.height = "auto"; 
     textarea.style.height = `${textarea.scrollHeight}px`; 

     setRetrivedInfo((prev) => ({
       ...prev,
       [key]: textarea.value,
     }));
   };


  useEffect(() => {
    const fetchJournalPaperData = async () => {
      if (contenttype === "Journal Paper" && structureHeading?.content_id) {
        try {
          setLoading(true);
          const response = await documentJournalPaperBack(
            structureHeading.content_id
          );

          if (response) {
            setRetrivedInfo(response.retrived_info);
          } else {
            console.error("No response data received.");
          }
        } catch (error) {
          console.error("Error fetching journal paper data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

     const fetchProtocolDocumentData = async () => {
       if (contenttype === "Protocol Document" && structureHeading?.content_id) {
         try { 
           setLoading(true);
           const response = await documentJournalPaperBack(
             structureHeading.content_id
           );

           if (response) {
             setRetrivedInfo(response.retrived_info);
           } else {
             console.error("No response data received.");
           }
         } catch (error) {
           console.error("Error fetching journal paper data:", error);
         } finally {
           setLoading(false);
         }
       }
     };

    fetchJournalPaperData();
    fetchProtocolDocumentData();
  }, [contenttype, structureHeading?.content_id]); 


  useEffect(() => {
    const textareas = document.querySelectorAll(".FileExtract-input");
    textareas.forEach((textarea) => {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    });
  }, [retrivedInfo]);

 const handleFileExtract = async () => {
  setLoading(true);
  try {
    const userId = sessionStorage.getItem("userID");

    if (!userId) {
      throw new Error("User ID not found in session storage.");
    }

    const Data = {
      retrivedInfo: retrivedInfo,
      convertedMarkdown: convertedMarkdown,
      extractedFields: extractedFields,
      contentIDFileExtract: contentIDFileExtract,
      title: journalPaperTitle,
    };

    let response = null;

    if (contenttype === "Journal Paper") {
      response = await documentFileExtract(Data, userId);
    } else if (contenttype === "Protocol Document") {
      response = await documentProtocolDocFileExtract(Data, userId);
    } else if (contenttype === "GRD") {
      response = await documentProtocolDocFileExtract(Data, userId);
      setFilePath(response?.file_path)
    }

    if (response) {
      setStructureHeading(response);
      navigate("/documentEditor");
    } else {
      console.error("Retrieved info not found in response.");
      toast.error("Unexpected error: Retrieved info not found.");
    }
  } catch (error) {
    console.error("Error in handleFileExtract:", error);
    toast.error("An error occurred during the extraction process.");
  } finally {
    setLoading(false);
  }
};


  return (
    <div className="content-structure-page">
      {loading && (
        <div className="loading-heading-container">
          <div className="loading-heading-box">
            <img
              src={AnimationFileExtract}
              alt="Loading animation"
              className="loading-heading-animation"
            />
            <div className="loading-heading-div">
              <span className="loading-heading-text">
                Processing, Please wait
              </span>
              <img
                src={PreloaderDocument}
                alt="Loading dots"
                className="loading-heading-dots"
              />
            </div>
          </div>
        </div>
      )}
      <div className={`top-navigation ${loading ? "dim-content" : ""}`}>
        <button
          type="button"
          className="back-button"
          onClick={() => navigate("/documentGeneration")}
        >
          ← Back
        </button>
        <div className="contentType">
          <h3>Content Structure</h3>
        </div>
        <div className="contentType">
          <p>Content Generation &gt; {contenttype} &gt; FileExtract</p>
        </div>
      </div>
      <div
        className={`content-structure-layout ${loading ? "dim-content" : ""}`}
      >
        <div className="form-container">
          <form className="form-FileExtract">
            {retrivedInfo &&
              Object.entries(retrivedInfo).map(([key, value]) => (
                <React.Fragment key={key}>
                  <label
                    className="labelFileExtract"
                    htmlFor={`input-${key}`}
                    data-label={key.replace(/_/g, " ")}
                  >
                    {key.replace(/_/g, " ")}
                  </label>
                  <textarea
                    id={`input-${key}`}
                    value={value}
                    className="FileExtract-input"
                    onChange={(e) => handleInputChange(e, key)}
                    rows={1}
                  />
                </React.Fragment>
              ))}
          </form>
        </div>
        <div className="form-actionsStructurebutton">
          <button
            type="button"
            className="cancel-button"
            onClick={() => navigate("/documentGeneration")}
          >
            Cancel
          </button>
          <button
            className="proceed-button"
            type="button"
            onClick={handleFileExtract}
          >
            Generate Content
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocumentFileExtract;


import React, { useContext, useEffect } from "react";
import { commonStr } from "../../utils/constants/commonStrings";
import { messageIcon, messageIcon1 } from "../../utils/images";
import { useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import "./styles.scss";
import SidebarNavFiles from "../../components/LanguageTranslation";

import {
  dropCollectionList,
  deleteAllCollectionFileList,
} from "../../api/Postaction";
import { MyContext } from "../../context/ContextApi";

function SidebarCurrentSession({
  handleClickNew,
  activeContentGenUrl,
  activeContentUrl,
  activefileUploadingUrl,
  activeMedLingoUrl,
  activeMedPulseUrl,
  activeMedInsightsUrl,
  activeMlrUrl,
  activePersonalIdentityUrl,
  activeWaterMarkUrl,
  activePersonalIdentityImageUrl,
  activeOmnilensUrl,
  activeSentimentAnalysisUrl,
  activeMedLingoUrlSenti,
  activeMedPulseUrlSenti,
  activeMedInsightsUrlSenti,
  currentSession,
  editSession,
  handleEditCurrentSession,
  handleSessionNameChange,
  handleSaveClick,
  handleCloseClick,
  activeCallInsights_2,
  activePromptExtractFilesPage,
  activeDocumentGeneration,
  activeDocumentStructure,
  activeDocumentHeading,
  activeDocumentEditor,
  activeDocumentFileExtract,
  activePubmedUrl,
}) {
  const navigate = useNavigate();
  const {
    fileContentList,
    documents,
    setSelectedFileUrls,
    newQuery,
    clearDocuments,
    setSelectedFile,
    setValue,
  } = useContext(MyContext);

  const userName = sessionStorage.getItem("firstname");

  const handleComponentChange = (path) => {
    navigate(path);
  };

  const handleComponentChangeSenti = (path) => {
    navigate(path);
  };

  const handleClickFileChange = (data) => {
    //setSelectedFileUrls(url);
    setSelectedFileUrls((prevUrl) =>
      prevUrl === data?.url ? prevUrl : data?.url
    );
    setSelectedFile(data);
  };

  const handleNewSessionClick = async () => {
    try {
      if (activeSentimentAnalysisUrl) {
        setValue("1");
      }

      let body = {
        file_list: fileContentList,
        collection_name: userName,
      };

      if (activeContentGenUrl) {
        await dropCollectionList(userName);
        await deleteAllCollectionFileList(body);
      }
      handleClickNew();
    } catch (error) {
      console.error("Error dropping collection:", error);
    }
  };

  useEffect(() => {
    setSelectedFileUrls("");
    clearDocuments();
  }, [newQuery]);

  return (
    <div className="current-session">
      <div className="current-session__newchat" onClick={handleNewSessionClick}>
        <div
          className={
            activeCallInsights_2 ||
            activePromptExtractFilesPage ||
            activeDocumentGeneration ||
            activeDocumentStructure ||
            activeDocumentHeading ||
            activeDocumentEditor ||
            activeDocumentFileExtract
              ? ""
              : "current-session__newchat__btn"
          }
        >
          {activeCallInsights_2 || activePromptExtractFilesPage
            ? null
            : activeContentGenUrl ||
                activefileUploadingUrl ||
                activeSentimentAnalysisUrl ||
                activeMedLingoUrlSenti ||
                activeMedPulseUrlSenti ||
                activeMedInsightsUrlSenti ||
                activeMedLingoUrl ||
                activeMedPulseUrl ||
                activeMedInsightsUrl ||
                activeMlrUrl ||
                activePersonalIdentityImageUrl ||
                activeWaterMarkUrl ||
                activePersonalIdentityUrl ||
                activeOmnilensUrl ||
                activePubmedUrl
              ? commonStr.newSession
              : activeDocumentGeneration ||
                  activeDocumentStructure ||
                  activeDocumentHeading ||
                  activeDocumentEditor ||
                  activeDocumentFileExtract
                ? ""
                : commonStr.newQuery}
        </div>
      </div>
      {(activefileUploadingUrl ||
        activeMedLingoUrl ||
        activeMedPulseUrl ||
        activeMedInsightsUrl) && (
        <div className="col-12 sidebarNav">
          <SidebarNavFiles handleComponentChange={handleComponentChange} />
        </div>
      )}

      {(activeMlrUrl ||
        activePersonalIdentityImageUrl ||
        activeWaterMarkUrl ||
        activePersonalIdentityUrl) && (
        <>
          <div className="font800 mt-5">&ensp; {commonStr.fileList}</div>
          <div className="fileListMenu">
            {documents.length > 0 ? (
              <ul>
                {documents.map((doc, index) => (
                  <li
                    onClick={() => handleClickFileChange(doc)}
                    key={index}
                    style={{ listStyle: "none" }}
                  >
                    <div className="d-flex align-items-center">
                      <span>
                        <InsertDriveFileOutlinedIcon />
                      </span>
                      <span className="fileListSpan">{doc.file_name}</span>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="displayFlexCenter h-100 mt-5">
                No files available
              </div>
            )}
          </div>
        </>
      )}

      {!activeContentUrl &&
        !activeContentGenUrl &&
        !activefileUploadingUrl &&
        !activeSentimentAnalysisUrl &&
        activeMedLingoUrlSenti &&
        activeMedPulseUrlSenti &&
        activeMedInsightsUrlSenti &&
        !activeMedLingoUrl &&
        !activeMedPulseUrl &&
        !activeMedInsightsUrl &&
        !activeMlrUrl &&
        !activePersonalIdentityUrl &&
        !activeWaterMarkUrl &&
        !activePersonalIdentityImageUrl &&
        !activePubmedUrl && (
          <div className="current-session__border mt-4 px-2 py-3">
            <div className="current-session__border__titlewrap">
              <img src={messageIcon} alt="sidebar" />
              <p className="current-session__border__titlewrap__title">
                {commonStr.currentSession}
              </p>
            </div>
            {currentSession !== "" || editSession ? (
              <div className="current-session__active mt-3">
                <img src={messageIcon1} alt="sidebar" />
                &ensp;
                <div className="current-session__inputwrap">
                  {editSession ? (
                    <input
                      type="text"
                      value={currentSession}
                      onChange={handleEditCurrentSession}
                      className="current-session__inputwrap__input"
                    />
                  ) : (
                    <div
                      className="current-session__inputwrap__inputvalue"
                      onClick={handleSessionNameChange}
                    >
                      {currentSession}
                    </div>
                  )}
                  <div className="current-session__iconwrap">
                    {editSession ? (
                      <>
                        <TiTick
                          size={25}
                          className="current-session__iconwrap__iconwhite"
                          onClick={handleSaveClick}
                        />

                        <IoIosClose
                          size={25}
                          className="current-session__iconwrap__iconwhite"
                          onClick={handleCloseClick}
                        />
                      </>
                    ) : (
                      <BiEdit
                        size={20}
                        className="current-session__iconwrap__iconwhite ml-1"
                        onClick={handleSessionNameChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div>{"Testing"}</div>
            )}
          </div>
        )}

      {!activeContentUrl &&
        !activeContentGenUrl &&
        !activefileUploadingUrl &&
        !activeSentimentAnalysisUrl &&
        activeMedLingoUrlSenti &&
        activeMedPulseUrlSenti &&
        activeMedInsightsUrlSenti &&
        !activeMedLingoUrl &&
        !activeMedPulseUrl &&
        !activeMedInsightsUrl &&
        !activeMlrUrl &&
        !activePersonalIdentityUrl &&
        !activeWaterMarkUrl &&
        !activePersonalIdentityImageUrl &&
        activePubmedUrl && (
          <div className="font600 mt-2">&ensp; {commonStr.sessionHistory}</div>
        )}
    </div>
  );
}

export default SidebarCurrentSession;

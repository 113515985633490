import React, { useState, useRef, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize";
import { MyContext } from "../../context/ContextApi";
import { BsSendFill } from "react-icons/bs";
import { EditorBot } from "../../utils/images";
import { generateImage } from "../../api/Postaction";
import {
  documentArticleAIGenerated,
  documentDynamicBannerAIGenerated,
  documentRepTriggerEmailAIGenerated,
  documentJournalPaperAIGenerated,
  documentAIRegenerated,
  documentContentDownload,
  documentContentSave,
  documentJPDocxDownload,
} from "../../api/Postaction";
import { documentPlagiarismChecker } from "../../api/Postaction";
import { ScanDocument } from "../../utils/images/index.js";
import { PreloaderDocument } from "../../utils/images/index.js";
import { toast } from "react-toastify";
import { FaRegImage } from "react-icons/fa6";
import { LiaAtlassian } from "react-icons/lia";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { MdOutlineDocumentScanner } from "react-icons/md";
import { EditAI } from "../../utils/images/index.js";
import {
  json_icon,
  DOCX,
  Teams,
  Gmail,
  Slack,
} from "../../utils/images/index.js";
import "react-quill/dist/quill.snow.css";
import "./documentEditor.css";

Quill.register("modules/imageResize", ImageResize);
Quill.import("ui/icons").undo = "↪";
Quill.import("ui/icons").redo = "↩";
const BackgroundStyle = Quill.import("attributors/style/background");
BackgroundStyle.whitelist = ["yellow", "pink", "lightgreen", "red"];
Quill.register(BackgroundStyle, true);
const Color = Quill.import("attributors/style/color");
const Background = Quill.import("attributors/style/background");
Quill.register(Color, true);
Quill.register(Background, true);
const Size = Quill.import("attributors/style/size");
Size.whitelist = [
  "8px",
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "24px",
  "36px",
  "48px",
  "72px",
];
Quill.register(Size, true);

const undoHandler = function () {
  this.quill.history.undo();
};

const redoHandler = function () {
  this.quill.history.redo();
};

const DocumentEditor = () => {
  const navigate = useNavigate();
  const {
    structureHeading,
    contenttype,
    contentHeading,
    rawHtmlContent,
    contentID,
    setBackButton,
    viewPage,
    filePath,
    setStructureHeading,
    setRawHtmlContent,
    setContentID,
    retrivedInfo,
  } = useContext(MyContext);
  const [editorContent, setEditorContent] = useState("");
  const [showOptionsAI, setShowOptionsAI] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [inputText, setInputText] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [showModal, setShowModal] = useState(false);
  const [generatedOptions, setGeneratedOptions] = useState([]);
  const [referenceModalData, setReferenceModalData] = useState([]);
  const [isReferenceModalOpen, setIsReferenceModalOpen] = useState(false);
  const [plagiarismResults, setPlagiarismResults] = useState(null);
  const [plagiarismScore, setPlagiarismScore] = useState();
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageloading, setImageLoading] = useState(false);
  const [ailoading, setAILoading] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [contentId, setContentId] = useState(null);
  const [selectionIndex, setSelectionIndex] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const [showOptionsShare, setShowOptionsShare] = useState(false);
  const [fileType, setFileType] = useState("");
  const quillRef = useRef(null);

  const handleIgnore = () => {
    setPlagiarismResults(null);
  };

  useEffect(() => {
    if (rawHtmlContent) {
      setEditorContent(rawHtmlContent);
      setContentId(contentID);
    } else if (structureHeading?.generated_content) {
      setEditorContent(structureHeading.generated_content || "");
      setReferenceModalData(structureHeading.title);
      setContentId(structureHeading.content_id);
      setContentID(structureHeading.content_id);
    }
  }, [rawHtmlContent, structureHeading, contentID]);

  const handleSelection = () => {
    const quillEditor = quillRef.current.getEditor();
    const selection = quillEditor.getSelection();

    if (selection && selection.length > 0) {
      const selectedText = quillEditor.getText(
        selection.index,
        selection.length,
      );
      setSelectedText(selectedText);
      setSelectionIndex(selection.index);

      const bounds = quillEditor.getBounds(selection.index, selection.length);
      setTooltipPosition({ top: bounds.top - 10, left: bounds.left });

      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  };

  const handleShareOptionClick = (platform) => {
    let url = "";

    switch (platform) {
      case "Teams":
        url = "https://teams.microsoft.com/l/chat/0/0?users="; 
        break;
      case "Gmail":
        url =
          "https://mail.google.com/mail/?view=cm&fs=1&to=&su=Shared%20Document&body=Please%20check%20this%20document.";
        break;
      case "Slack":
        url = "https://slack.com/app_redirect?channel=general";
        break;
      default:
        console.error("Unknown share platform");
        return;
    }

    window.open(url, "_blank");
  };

  const handleShareClick = () => {
    setShowOptionsShare((prev) => !prev);
    setShowOptions(false);
  };

  const handleDownloadClick = () => {
    setShowOptions((prev) => !prev);
    setShowOptionsShare(false);
  };

  const handleFileTypeClick = async (type) => {
    setFileType(type);
    setShowOptions(false);
    await handleDownload(type);
  };

  const handleDownload = async (type) => {
    if (filePath) {
      try {
        const response = await fetch(filePath);
        if (!response.ok) {
          throw new Error(
            `File not available: ${response.status} ${response.statusText}`
          );
        }
        const blob = await response.blob();
        if (blob.size === 0) {
          throw new Error("Downloaded file is empty");
        }
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        let fileName = "downloadedFile.docx";
        try {
          const urlObj = new URL(filePath);
          const pathSegments = urlObj.pathname.split("/");
          fileName = pathSegments.pop() || fileName;
        } catch (error) {
          console.error("Error parsing URL:", error);
        }
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Download failed:", error);
        alert(error.message);
      }
    } else {
      console.error("Failed to request download: No file path provided");
      alert("No file path provided.");
    }
  };

  const handleJPDocxDownload = async (fileType) => {
    const payload = {
      s3_path: structureHeading?.file_path || filePath,
    };

    try {
      const response = await documentJPDocxDownload(payload);

      if (response.data) {
        const blob = response.data;
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");

        const fileExtension = fileType.toLowerCase();
        link.href = url;
        link.download = `DocumentContent.${fileExtension}`;
        link.click();
        URL.revokeObjectURL(url);
      } else {
        console.error("Failed to request download: No data in response");
      }
    } catch (error) {
      console.error("Error while requesting download:", error);
    }
  };

  const handleJPJsonDownload = async() =>{
    const jsonString = JSON.stringify(retrivedInfo, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "ProtocolDocument.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }


  const handleSave = async () => {
    const payload = {
      generated_content: editorContent,
      file_path: structureHeading?.file_path || filePath,
    };

    try {
      const response = await documentContentSave(payload);

      if (response.data) {
        toast.success("Content saved successfully!");
        setStructureHeading("");
        setRawHtmlContent("");
        navigate("/documentGeneration");
      } else {
        toast.error("Failed to save content. Please try again.");
        console.error("Failed to save content. Please try again.");
      }
    } catch (error) {
      toast.error("Error saving content. Please try again.");
      console.error("Error while saving content:", error);
    }
  };

  const handleEditWithAI = () => {
    setShowTooltip(false);
    setShowModal(true);
  };

  const handleGenerateImage = async () => {
    setImageLoading(true);
    let payload = {
      custom_input: selectedText,
    };

    try {
      const response = await generateImage(payload);
      const imageUrl = response.base64_string;
      setImageUrl(imageUrl);
      insertImageAboveSelectedText(imageUrl);
    } catch (error) {
      console.error("Failed to Generate Image:", error);
      toast.error("Failed to Generate Image.");
    } finally {
      setImageLoading(false);
    }
  };

  const insertImageAboveSelectedText = (imageUrl) => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();

    if (range) {
      const imageHtml = `<img src="${imageUrl}" alt="Generated Image" style="max-width: 100%; height: auto;" />`;
      quill.clipboard.dangerouslyPasteHTML(range.index, imageHtml);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const resetModalContent = () => {
    setChatHistory([]);
    setSelectedText("");
    setShowOptionsAI(false);
    setInputText("");
    setSelectedTab("Option 1");
  };

  const handleGenerateContent = () => {
    toast.success("Generated Content Succesfully");
    setStructureHeading("");
    setRawHtmlContent("");
    navigate("/documentGeneration");
  };

  const handlePlagiarismScan = async () => {
    const userId = sessionStorage.getItem("userID");
    try {
      setLoading(true);
      const quillEditor = quillRef.current.getEditor();
      const content = quillEditor.root.innerHTML;
      const generatedresult = quillEditor.root.innerText;
      const payload = {
        content_id: contentId,
        generated_result: generatedresult,
      };
      const response = await documentPlagiarismChecker(payload, userId);
      const percentPlagiarism = response.percentPlagiarism;
      setPlagiarismScore(percentPlagiarism);
      const results = response.sources.flatMap((source) =>
        source.matches.map((match) => ({
          url: source.url,
          score: match.score.toFixed(3),
          matchText: match.matchText,
        })),
      );

      setPlagiarismResults(results);
      toast.success("Plagiarism scan completed!");
    } catch (error) {
      console.error("Error in plagiarism scan:", error);
      toast.error("Failed to perform plagiarism scan.");
    } finally {
      setLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (inputText.trim() === "") return;

    setChatHistory((prevChat) => [...prevChat, { user: inputText, ai: null }]);

    const currentIndex = chatHistory.length;
    setLoadingIndex(currentIndex);
    setAILoading(true);

    const formData = {
      content_id: contentId,
      selected_text: selectedText,
      custom_prompt: inputText,
    };

    try {
      setInputText("");

      if (!contenttype && !contentHeading) {
        throw new Error("Neither contenttype nor contentHeading is provided");
      }

      let response;

      if (contenttype) {
        switch (contenttype) {
          case "Article":
            response = await documentArticleAIGenerated(formData);
            break;
          case "Dynamic Banner":
            response = await documentDynamicBannerAIGenerated(formData);
            break;
          case "Email":
            response = await documentRepTriggerEmailAIGenerated(formData);
            break;
          case "Journal Paper":
            response = await documentJournalPaperAIGenerated(formData);
            break;
          default:
            throw new Error("Invalid content type");
        }
      }

      if (contentHeading) {
        switch (contentHeading) {
          case "Article":
            response = await documentArticleAIGenerated(formData);
            break;
          case "Dynamic Banner":
            response = await documentDynamicBannerAIGenerated(formData);
            break;
          case "Rep Triggered Email":
            response = await documentRepTriggerEmailAIGenerated(formData);
            break;
          case "Journal Paper":
            response = await documentJournalPaperAIGenerated(formData);
            break;
          default:
            throw new Error("Invalid content type");
        }
      }

      const aiResponse = response.message;

      setChatHistory((prevChat) =>
        prevChat.map((entry, idx) =>
          idx === currentIndex ? { ...entry, ai: aiResponse } : entry,
        ),
      );
    } catch (error) {
      console.error("Failed to fetch AI response:", error);
      toast.error("Failed to generate AI response.");
    } finally {
      setAILoading(false);
      setLoadingIndex(null);
    }
  };

  const handleRegenerateClick = async () => {
    try {
      if (!chatHistory.length || !selectedText) {
        toast.error("Previous response or selected text is missing.");
        return;
      }

      const previousresponse = chatHistory[chatHistory.length - 1].ai;

      const formData = {
        previousresponse,
        selectedText,
      };

      const response = await documentAIRegenerated(formData);

      if (response) {
        const newOptions = Object.entries(response).map(
          ([key, value]) => `${key}: ${value}`,
        );

        setGeneratedOptions(newOptions);
        setSelectedTab("Option 1");
        setShowOptionsAI(true);
        toast.success("AI has provided more options!");
      } else {
        throw new Error("Invalid response format.");
      }
    } catch (error) {
      console.error("Failed to regenerate response:", error);
      toast.error("Failed to regenerate response. Please try again.");
    }
  };

  const handleInsertResponse1 = (aiResponse) => {
    const quillEditor = quillRef.current.getEditor();

    if (selectionIndex !== null) {
      quillEditor.deleteText(selectionIndex, selectedText.length);
      quillEditor.insertText(selectionIndex, aiResponse);
      quillEditor.setSelection(selectionIndex + aiResponse.length);
    } else {
      const cursorPosition = quillEditor.getSelection()?.index || 0;
      quillEditor.insertText(cursorPosition, aiResponse);
      quillEditor.setSelection(cursorPosition + aiResponse.length);
    }

    closeModal();
    resetModalContent();
  };

  const handleInsertResponse2 = (aiResponse) => {
    const quillEditor = quillRef.current.getEditor();

    if (selectionIndex !== null) {
      quillEditor.deleteText(selectionIndex, selectedText.length);
      quillEditor.insertText(selectionIndex, aiResponse);
      quillEditor.setSelection(selectionIndex + aiResponse.length);
    } else {
      const cursorPosition = quillEditor.getSelection()?.index || 0;
      quillEditor.insertText(cursorPosition, aiResponse);
      quillEditor.setSelection(cursorPosition + aiResponse.length);
    }

    closeModal();
    resetModalContent();
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();

      quill.root.addEventListener("click", (e) => {
        const target = e.target;
        if (
          target &&
          target.textContent &&
          target.textContent.match(/\[\d+\]/)
        ) {
          const refNumber = target.textContent.match(/\[(\d+)\]/)[1];
          handleReferenceClick(refNumber);
        }
      });
    }
  }, []);

  const handleReferenceClick = async (refNumber) => {
    setIsReferenceModalOpen(true);
  };

  useEffect(() => {
    if (plagiarismResults) {
      document.querySelector(".ql-editor").classList.add("ql-editor-Min");
      moveModalToContainer();
    } else {
      document.querySelector(".ql-editor").classList.remove("ql-editor-Min");
    }
  }, [plagiarismResults]);

  // useEffect(() => {
  //   const editor = document.querySelector(".ql-editor");
  //   const container = document.querySelector(".ql-toolbar.ql-snow");

  //   if (plagiarismResults) {
  //     editor.classList.add("ql-editor-Min");
  //     container.classList.add("ql-container-Min");
  //     moveModalToContainer();
  //   } else {
  //     editor.classList.remove("ql-editor-Min");
  //     container.classList.remove("ql-container-Min");
  //   }
  // }, [plagiarismResults]);

  const moveModalToContainer = () => {
    const modal = document.querySelector(".plagiarism-modal");
    const container = document.querySelector(".ql-container.ql-snow");
    if (modal && container) {
      container.appendChild(modal);
    }
  };

  const renderModal = plagiarismResults && (
    <div className="plagiarism-modal">
      <h4 className="editor-plagiarism-heading">Plagiarism Check</h4>
      <div className="modal-plagiarism-contenteditor">
        <p>
          <strong>Plagiarism Percent: </strong>
          {plagiarismScore}%
        </p>
        {plagiarismResults.map((result, index) => (
          <div key={index} className="plagiarism-result">
            <p>
              <strong>URL: </strong>
              <a href={result.url} target="_blank" rel="noopener noreferrer">
                {result.url}
              </a>
            </p>
            <p>
              <strong>Score: </strong>
              {result.score}
            </p>
            <p>
              <strong>Text: </strong>
              {result.matchText}
            </p>
          </div>
        ))}
      </div>
      <div className="form-actions-plagiarism">
        <button
          className="Regenerate-button"
          type="button"
          onClick={handleIgnore}
        >
          Ignore
        </button>
        <button className="Regenerate-button" type="button">
          Regenerate
        </button>
        <button
          type="button"
          className="insert-button"
          // onClick={() => handleInsertResponse3(plagiarismResults)}
        >
          Replace
        </button>
      </div>
    </div>
  );

  return (
    <div className="editor-container">
      {loading && (
        <div className="loading-heading-container">
          <div className="loading-heading-box">
            <img
              src={ScanDocument}
              alt="Loading animation"
              className="loading-heading-editor-animation"
            />
            <div className="loading-heading-div">
              <span className="loading-heading-text">
                Scanning, Please wait
              </span>
              <img
                src={PreloaderDocument}
                alt="Loading dots"
                className="loading-heading-dots"
              />
            </div>
          </div>
        </div>
      )}
      <div className="editor-actions">
        <button
          className="back-button"
          onClick={() => {
            setStructureHeading("");
            setRawHtmlContent("");
            setBackButton("back");
            if (viewPage === true) {
              navigate("/documentGeneration");
            } else if (contenttype === "Article") {
              navigate("/documentHeading");
            } else if (contenttype === "Journal Paper") {
              navigate("/documentFileExtract");
            } else if (contenttype === "Protocol Document") {
              navigate("/documentFileExtract");
            } else if (contenttype) {
              navigate(`/documentStructure?content=${contenttype}`);
            } else {
              navigate("/documentGeneration");
            }
          }}
        >
          ← Back
        </button>
      </div>

      <div className="react-quill-container">
        <div className="editor-buttons">
          <div>
            <button className="share-button" onClick={handleShareClick}>
              Share
            </button>
            {showOptionsShare && (
              <div className="download-options">
                <div onClick={() => handleShareOptionClick("Teams")}>
                  <img src={Teams} alt="Teams" /> Teams
                </div>
                <div
                  className="tooltip-option"
                  onClick={() => handleShareOptionClick("Gmail")}
                >
                  <img src={Gmail} alt="Gmail" /> Gmail
                </div>
                <div
                  className="tooltip-option"
                  onClick={() => handleShareOptionClick("Slack")}
                >
                  <img src={Slack} alt="Slack" /> Slack
                </div>
              </div>
            )}
          </div>

          <div>
            <button className="download-button" onClick={handleDownloadClick}>
              Download
            </button>

            {showOptions && (
              <div className="download-options">
                {contenttype === "Journal Paper" ? (
                  <div onClick={() => handleJPDocxDownload("Docx")}>
                    <img src={DOCX} alt="Docx" /> Docx
                  </div>
                ) : contenttype === "Protocol Document" ? (
                  <>
                    <div onClick={() => handleJPDocxDownload("Docx")}>
                      <img src={DOCX} alt="Docx" /> Docx
                    </div>
                    <div onClick={() => handleJPJsonDownload("Json")}>
                      <img src={DOCX} alt="JSON" /> Json
                    </div>
                  </>
                ) : (
                  <>
                    <div onClick={() => handleFileTypeClick("Docx")}>
                      <img src={DOCX} alt="Docx" /> Docx
                    </div>
                    <div onClick={() => handleJPJsonDownload("Json")}>
                      <img src={json_icon} alt="JSON" /> Json
                    </div>
                    {/* <div onClick={() => handleFileTypeClick("PPTx")}>
                      <img src={PPT} alt="PPTx" /> PPT
                    </div>
                    <div onClick={() => handleFileTypeClick("Pdf")}>
                      <img src={PDF} alt="PDF" /> PDF
                    </div> */}
                  </>
                )}
              </div>
            )}
          </div>

          <button className="save-button" onClick={handleSave}>
            Save
          </button>
        </div>

        {showTooltip && (
          <div
            className="tooltip-button"
            style={{
              top: `${tooltipPosition.top}px`,
              left: `${tooltipPosition.left}px`,
            }}
          >
            {imageloading ? (
              <span className="loaderImage-text">
                Image generating, Please wait...
              </span>
            ) : (
              <>
                <button
                  className="edit-ai-button"
                  onClick={handleGenerateImage}
                >
                  Generate Image <FaRegImage style={{ marginBottom: "2px" }} />
                </button>
                <button className="edit-ai-button" onClick={handleEditWithAI}>
                  Edit with AI{" "}
                  <img
                    src={EditAI}
                    style={{ marginBottom: "2px" }}
                    alt="Edit AI"
                  />
                </button>
              </>
            )}
          </div>
        )}

        <ReactQuill
          ref={quillRef}
          value={editorContent}
          onChange={setEditorContent}
          onChangeSelection={handleSelection}
          theme="snow"
          modules={{
            imageResize: true,
            toolbar: {
              container: [
                ["undo", "redo"],
                [{ header: [1, 2, false] }],
                [
                  {
                    color: [
                      "#FF0000",
                      "#00FF00",
                      "#0000FF",
                      "black",
                      "orange",
                      "purple",
                      "pink",
                      "brown",
                      "#FFD700",
                      "cyan",
                      "gray",
                    ],
                  },
                ],
                [
                  {
                    background: [
                      "#FFFF00",
                      "#FFC0CB",
                      "#90EE90",
                      "#FF6347",
                      "#B0E0E6",
                      "#F0E68C",
                      "yellow",
                      "pink",
                      "lightgreen",
                      "red",
                      false,
                    ],
                  },
                ],
                ["bold", "italic", "underline", "strike"],
                ["link", "image"],
                [{ align: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                [
                  {
                    size: [
                      "8px",
                      "10px",
                      "12px",
                      "14px",
                      "16px",
                      "18px",
                      "24px",
                      "36px",
                      "48px",
                      "72px",
                    ],
                  },
                ],
              ],
              handlers: {
                undo: undoHandler,
                redo: redoHandler,
              },
            },
            history: {
              delay: 1000,
              maxStack: 100,
              userOnly: true,
            },
          }}
        />
      
        {plagiarismResults &&
          ReactDOM.createPortal(
            renderModal,
            document.querySelector(".ql-container.ql-snow")
          )}
      </div>

      <div className="form-actionsbutton">
        <button
          className="generate-content"
          type="button"
          onClick={handlePlagiarismScan}
        >
          <MdOutlineDocumentScanner size={20} />
          Plagiarism Scan
        </button>
        <button
          type="button"
          className="generate-content"
          onClick={handleGenerateContent}
        >
          Generate Content
        </button>
      </div>

      {showModal && (
        <div className="modal-overlayeditor">
          <div className="modaleditor">
            <div className="modal-headereditor">
              <div className="left-section">
                <button
                  className="back-button-arrow"
                  onClick={() => {
                    closeModal();
                    resetModalContent();
                  }}
                >
                  <MdOutlineArrowBackIosNew className="icon-adjust" />
                </button>
                <h5 className="edit-ai-title">
                  Edit with AI{" "}
                  <img
                    src={EditAI}
                    style={{ width: "20px", marginBottom: "3px" }}
                    alt="Edit AI"
                  />
                </h5>
              </div>
              <button
                className="close-button-chatbot"
                onClick={() => {
                  closeModal();
                  resetModalContent();
                }}
              >
                ×
              </button>
            </div>

            <div className="modal-content-wrapper">
              <h4 className="editorheading">Selected Text</h4>
              <div className="modal-p-contenteditor">
                <p>{selectedText}</p>
              </div>
              {chatHistory.map((entry, index) => (
                <div key={index} className="message-pair">
                  {!showOptionsAI ? (
                    <>
                      <div className="chat-message user-message">
                        <strong>User Prompt:</strong> {entry.user}
                      </div>

                      {loadingIndex === index && ailoading ? (
                        <span className="loaderAI-text">
                          AI Generating, Please wait...
                        </span>
                      ) : (
                        <>
                          {entry.ai && (
                            <>
                              <h4 className="editorheading">AI Generated</h4>
                              <p className="modal-contenteditor">{entry.ai}</p>
                            </>
                          )}
                          <div className="form-actions">
                            <button
                              className="Regenerate-button"
                              type="button"
                              onClick={handleRegenerateClick}
                            >
                              Regenerate
                            </button>
                            <button
                              type="button"
                              className="insert-button"
                              onClick={() => handleInsertResponse1(entry.ai)}
                            >
                              Replace
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    showOptionsAI && (
                      <div className="modal-option-contenteditor">
                        <h4 className="editorheading">
                          AI Regenerated Options
                        </h4>
                        <div className="tabs">
                          <div className="tab active">
                            {generatedOptions[0]?.split(":")[0]}{" "}
                          </div>
                        </div>

                        <div className="tab-content">
                          <p className="tab-contenteditor">
                            {generatedOptions[0]?.split(":")[1]}{" "}
                          </p>

                          <div className="form-actions">
                            <button
                              className="Regenerate-button"
                              type="button"
                              onClick={handleRegenerateClick}
                            >
                              Again-Regenerate
                            </button>
                            <button
                              type="button"
                              className="insert-button"
                              onClick={() => {
                                const selectedTabIndex =
                                  generatedOptions.findIndex(
                                    (option, index) =>
                                      selectedTab === `Option ${index + 1}`
                                  );
                                const aiResponse =
                                  selectedTabIndex !== -1
                                    ? generatedOptions[selectedTabIndex].split(
                                        ":"
                                      )[1]
                                    : "";
                                handleInsertResponse2(aiResponse);
                              }}
                            >
                              Replace
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              ))}
              <div className="chatbot-message">
                <div className="chatbot-icon">
                  <div className="icon-background">
                    <img
                      src={EditorBot}
                      alt="EditorBot"
                      className="icon-image"
                    />
                  </div>
                </div>
                <input
                  type="text"
                  placeholder="Ask AI anything..."
                  className="chatbot-input"
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSendMessage();
                    }
                  }}
                />
                <BsSendFill
                  className="chatbot-send-button"
                  onClick={handleSendMessage}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isReferenceModalOpen && referenceModalData && (
        <div className="modal-citation-overlay">
          <div className="modal-citation-container">
            <div className="modal-citation-header">
              <h4>Citations</h4>
              <button
                className="close-citation-btn"
                onClick={() => setIsReferenceModalOpen(false)}
              >
                &times;
              </button>
            </div>
            <div className="modal-citation-body">
              {referenceModalData.map((citation, index) => (
                <div key={index} className="citation-card">
                  <p>
                    <strong>{index + 1}</strong> {citation}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentEditor;

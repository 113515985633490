import React from "react";
import { commonStr } from "../../utils/constants/commonStrings";
import "./styles.scss";
import CommonClearBtn from "../commonClearBtn";

function SidBarPubmedFilter({
  onClickClear,
  showListData,
  inputOnChange,
  filterContent,
  type = "checkbox",
  lableTitle = commonStr.sourceFile,
  customClass = "",
  isGenePage = false,
  name,
}) {
  return (
    <div className={`source-filter ${customClass}`}>
      <div className="source-filter__labelwrap">
        <p className="fontBold">{lableTitle}</p>
        <CommonClearBtn isVisible onClick={onClickClear} />
      </div>
      <div className="mt-1">
        {showListData &&
          showListData?.length > 0 &&
          showListData?.map((item) => (
            <div key={item.value}>
              <label className="source-filter__inputlabel">
                <input
                  name={name}
                  className="iconHover"
                  type={type}
                  value={item.value}
                  onChange={inputOnChange}
                  checked={
                    type === "checkbox"
                      ? filterContent.source.includes(item.value)
                      : isGenePage
                        ? filterContent[name] === item.value
                        : filterContent.relevance === item.value
                  }
                />
                <p className="source-filter__inputlabel__label">{item.label}</p>
              </label>
            </div>
          ))}
      </div>
    </div>
  );
}

export default SidBarPubmedFilter;

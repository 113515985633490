const dev = process.env.REACT_APP_API_DEV_URL; // Test
const docSonar = process.env.REACT_APP_API_DEV_URL_DOCSONAR; //DOCSONAR
const pubmed = process.env.REACT_APP_API_DEV_URL_PUBMED; //PUBMED
const sentiment = process.env.REACT_APP_API_DEV_URL_SENTIMENT_ANALYSIS; //SENTIMENT ANALYSIS
const commercial = process.env.REACT_APP_API_DEV_URL_COMMERCIAL; // COMMERCIAL DATA
const mlr = process.env.REACT_APP_API_DEV_URL_MLR; //MLR
const call_insights = process.env.REACT_APP_API_DEV_URL_CALL_INSIGHTS; //CALL INSIGHTS
const hcp_mlt = process.env.REACT_APP_API_DEV_URL_HCP_MLT; //HCP MLT
const co_pilot = process.env.REACT_APP_API_DEV_URL_COPILOT; // SALES REP CO-PILOT
const data_lens = process.env.REACT_APP_API_DEV_URL_DATA_LENS; //DATA LENS
const omni_chatbot = process.env.REACT_APP_API_DEV_URL_OMNI_CHATBOT; //OMNI CHATBOT
const dash_lens = process.env.REACT_APP_API_DEV_URL_DASH_LENS; //DASH LENS
const content_generation = process.env.REACT_APP_API_DEV_URL_CONTENT_GENERATION; //CONTENT GENERATION
const data_detective = process.env.REACT_DATA_DETECTIVE; //DATA DETECTIVE
const call_insights_2 = process.env.REACT_APP_API_DEV_URL_CALL_INSIGHTS_2;
const geneInspector = process.env.REACT_APP_API_DEV_URL_GENE_INSPECTOR;
const contentTagging = process.env.REACT_APP_API_DEV_URL_GENE_INSPECTOR;
// const hr_chatbot = process.env.REACT_APP_API_DEV_URL_HR_CHATBOT
const document_generation = process.env.REACT_APP_API_DEV_URL_DOCUMENT_GENERATION //DOCUMENT GENERATION

export const postUrl = {
  sendToken: `${dev}/sso/authentication`,
  createPersona: `${data_lens}/data_lens/add_persona`,
  createUser: `${dev}/login/create_user/`,
  login: `${dev}/login/user_login/`,
  prompt: `${data_lens}/data_lens_graph/chat_response`,
  sessionlist: `${data_lens}/data_lens/get_sessions/`,
  sessionHistory: `${data_lens}/data_lens/list_session_chat/`,
  audio: `${dev}/Email/create_audio/`,
  sendEmail: `${dev}/Email/send_email/`,
  sendEmailDataLens: `${data_lens}/Email/send_email/`,
  shareWhatsApp: `${dev}/WhatsApp/send_message_text/`,
  kpi: `${dev}/kpi/summary/`,

  //docsonar post
  uploadDocuments: `${docSonar}/document_search/upload_document`,
  documentPrompt: `${docSonar}/document_search/retrieve_from_document`,
  documentChathistory: `${docSonar}/document_session/display_doc_search_session_chat`,
  localDocument: `${docSonar}/document_search/local_doc_upload`,
  bucketList: `${docSonar}/document_search/bucket_list`,
  document_audio_history: `${docSonar}/document_session/document_audio_history`,
  feedback: `${docSonar}/document_session/feedback-data`,
  decodedContent: `${docSonar}/document_search/convert_path_to_encoded_content`,
  getEvaluate: `${docSonar}/document_search/get_evaluate_response`,
  summarizeDocSonar: `${docSonar}/document_search/summary_test`,
  documentSessionHistory: `${docSonar}/document_session/get_doc_search_sessions`,
  documentDropdown: `${docSonar}/document_session/get_collection/`,
  documentCollectionLastUsed: `${docSonar}/document_session/collection_last_used`,

  documentNewsession: `${dev}/document_session/new_doc_search_session/`,
  createCollection: `${dev}/login/create_collection/`,
  dashboardPrompt: `${dash_lens}/dashboard_search/dashboard_chat/`,
  summaryDashboard: `${dash_lens}/dashboard_search/dashboard_summary/`,
  dashboardSessionHistory: `${dash_lens}/dasboard_session/get_dashboard_search_sessions/`,
  dashboardChathistory: `${dash_lens}/dasboard_session/Display_dashboard_session_chat/`,
  schedule: `${dev}/scheduler/create-rule/`,
  data_audio_history: `${data_lens}/data_lens/data_audio_history/`,
  dashboard_audio_history: `${dash_lens}/dasboard_session/dashboard_audio_history/`,
  //pubmed
  pubmedSearch: `${pubmed}/pubmed/pubmed_search/`,
  referencePapers: `${pubmed}/pubmed/reference_papers/`,
  upload_doc_pubmed: `${pubmed}/pubmed/upload_doc_pubmed/`,
  upload_local_document_pub: `${pubmed}/pubmed/upload_local_doc_pubmed/`,
  updating_disease_tagged: `${pubmed}/pubmed/updating_disease_tagged/`,
  pubmedSearchSummary: `${pubmed}/pubmed/pubmed_search_summary/`,
  new_chat_session: `${pubmed}/redis/new_chat_session/`,
  sessionHistoryPubMed: `${pubmed}/document_session/get_doc_search_sessions`,
  editSessionHistoryPubMed: `${pubmed}/document_session/rename_doc_search_sessions`,
  pubMedExistingHistory: `${pubmed}/document_session/display_doc_search_session_chat`,

  dataRenamesession: `${data_lens}/data_lens/rename_data_lens_sessions/`,
  documentRenamesession: `${docSonar}/document_session/rename_doc_search_sessions/`,
  dashboardRenamesession: `${dash_lens}/dasboard_session/rename_dashboard_search_sessions/`,
  dashRenameSession: `${dev}/text_to_dashboard/rename_dash_vista_chat_ses/`,
  modalSwapLogs: `${dev}/login/model_swap_logs/`,
  textToDashboard: `${dash_lens}/text_to_dashboard/txt_to_dash`,
  chartChatHistory: `${dash_lens}/text_to_dashboard/display_dash_vista_history/`,
  chartSessionHistory: `${dash_lens}/text_to_dashboard/get_dash_vista_sessions/`,
  audioPromptForData: `${dev}/login/Prompt_chat_speech/`,
  audioPromptForDoc: `${docSonar}/document_search/retrieve_from_document_speech/`,
  audioPromptForDash: `${dash_lens}/dashboard_search/dashboard_chat_speech/`,
  // uri_endpoint: `${dev}/session/uri_endpoint/`,
  search_get_data_json: `${dash_lens}/tableau_dashboard_search/get_data_json/`,
  tableau_dashboard_summary: `${dash_lens}/tableau_dashboard_search/dashboard_summary/`,
  tableau_dashboard_chat: `${dash_lens}/tableau_dashboard_search/dashboard_chat/`,
  tableau_dashboard_chat_speech: `${dash_lens}/tableau_dashboard_search/dashboard_chat_speech/`,
  tableauRenameSession: `${dash_lens}/tableau_dasboard_session/rename_dashboard_search_sessions/`,
  tableauSessionChat: `${dash_lens}/tableau_dasboard_session/Display_dashboard_session_chat/`,
  tableauAudioHistory: `${dash_lens}/tableau_dasboard_session/dashboard_audio_history/`,
  createWorkBook: `${dev}/login/create_workbook_kpi/`,
  fetchGeneData: `${geneInspector}/gene_inspector/fetch_gene_data/`,
  uploadDocGen: `${geneInspector}/gene_inspector/upload_local_doc_gene/`,
  addChartResponseInHistory: `${dash_lens}/text_to_dashboard/adding_dash_vista_history/`,
  uploadDocumetGt: `${contentTagging}/content_tagging/upload_document_ct/`,
  uploadLocalDocumetGt: `${contentTagging}/content_tagging/upload_local_document_ct/`,
  contentTaggingSearch: `${contentTagging}/content_tagging/content_tagging_search/`,
  contentDbList: `${contentTagging}/content_tagging/list_databases/`,
  contentTableList: `${contentTagging}/content_tagging/list_tables/`,
  contentTagTable: `${contentTagging}/content_tagging/insert_tags_into_table/`,
  contentTemplateUpload: `${content_generation}/content_generator/upload-template/`,
  contentGenerateInfo: `${content_generation}/content_generator/upload-info-doc/`,
  contentFileLists: `${content_generation}/content_generator/list-reference-file/`,
  contentGenerateReference: `${content_generation}/content_generator/extract-info-reference/`,
  contentRagaScore: `${content_generation}/content_generator/ragas-score/`,
  contentGenerateDocument: `${content_generation}/content_generator/generate-document/`,
  dropCollection: `${content_generation}/content_generator/drop-collection/`,
  translateDocumentUpload: `${hcp_mlt}/lang_trans_analytics/upload/`,
  translateDocumentUploadSenti: `${sentiment}/lang_trans_analytics/upload/`,
  translateColumnData: `${hcp_mlt}/lang_trans_analytics/Med_Lingo/`,
  translateColumnDataSenti: `${sentiment}/lang_trans_analytics/Med_Lingo/`,
  uniqueValueForColumnData: `${hcp_mlt}/lang_trans_analytics/MedInsights/column_values/unique_values/`,
  uniqueValueForColumnDataSenti: `${sentiment}/lang_trans_analytics/MedInsights/column_values/unique_values/`,
  dataResForMedInsight: `${hcp_mlt}/lang_trans_analytics/Med_Insights/`,
  dataResForMedInsightSenti: `${sentiment}/lang_trans_analytics/Med_Insights/`,
  getColumnDataForMedPulse: `${hcp_mlt}/lang_trans_analytics/Med_Pulse/`,
  getColumnDataForMedPulseSenti: `${sentiment}/lang_trans_analytics/Med_Pulse/`,
  uploadMlrDoc: `${mlr}/pii_checker/upload_pdf`,
  personalIdentityData: `${mlr}/pii_checker/process_pii_and_watermarks`,
  claimsTagging: `${mlr}/pii_checker/claims_tagging`,
  uploadLogo: `${mlr}/pii_checker/upload_logo_image`,
  deleteCollectionListFile: `${content_generation}/content_generator/delete-ref-file-s3/`,
  deleteCollectionAllListFile: `${content_generation}/content_generator/delete-all-file-s3/`,

  dataDetectivePrompt: `${data_detective}/gen_data/detective_chat`,
  datadecRenamesession: `${data_detective}/session/rename_sessions/`,
  dataDetectiveSession: `${data_detective}/session/get_sessions/`,
  dataDetectiveChatHistory: `${data_detective}/session/list_session_chat/`,
  dataDetectiveChatFeedback: `${data_detective}/session/feedback-data/`,

  //ContentGeneration

  documentStructureArticlefields: `${document_generation}/content_generation/article`,
  documentStructureDynamicBannerfields: `${document_generation}/content_generation/dynamic-banner`,
  documentPlagiarismChecker: `${document_generation}/content_generation/plagiarism_checker`,
  documentStructureArticleFileUpload: `${document_generation}/content_generation/upload_multiple_documents`,
  documentStructureDynamicBannerFileUpload: `${document_generation}/content_generation/dynamic_banner_upload_multiple_documents`,
  documentStructureArticleheadings: `${document_generation}/content_generation/article/generate`,
  documentArticleAIGenerated: `${document_generation}/content_generation/rephrase_article`,
  documentDynamicBannerAIGenerated: `${document_generation}/content_generation/rephrase_dynamic_banner `,
  documentRepTriggerEmailAIGenerated: `${document_generation}/content_generation/rephrase_rep_triggered_email `,
  documentJournalPaperAIGenerated: `${document_generation}/content_generation/rephrase_journal_paper`,
  documentProtocolDocumentGenerated: `${document_generation}/content_generation/rephrase_journal_paper`,
  generateImage: `${document_generation}/content_generation/generate-image/`,
  documentStructureRepTriggerEmailfields: `${document_generation}/content_generation/rep_triggered_email`,
  documentStructureRepTriggerEmailFileUpload: `${document_generation}/content_generation/rep_trigger_email_upload_multiple_documents`,
  documentStructureFileUpload: `${document_generation}/content_generation/documents`,
  documentFileExtract: `${document_generation}/content_generation/generated_documents`,
  documentAIRegenerated: `${document_generation}/content_generation/regenerate_llm_response`,
  documentContentDownload: `${document_generation}/content_generation/article_download/`,
  documentContentRename: `${document_generation}/content_generation/rename/`,
  documentContentSave: `${document_generation}/content_generation/save_content`,
  documentJPDocxDownload: `${document_generation}/content_generation/download_journal_paper/`,
  documentProtocolDocFileUpload: `${document_generation}/content_generation/ipd_documents`,
  documentProtocolDocFileExtract: `${document_generation}/content_generation/ipd_generated_documents`,
  documentGRDDocFileUpload: `${document_generation}/content_generation/ipd_documents_with_table`,
  documentGRDDocFileExtract: `${document_generation}/content_generation/GRD_generated_documents`,

  // commercial data detective
  comDetectivePrompt: `${commercial}/gen_data/detective_chat`,
  comDetectiveSession: `${commercial}/session/get_sessions/`,
  comDetetectiveRename: `${commercial}/session/rename_sessions/`,
  comDetectiveChatHistory: `${commercial}/session/list_session_chat/`,
  comDetectiveUpload: `${commercial}/session/upload_file/`,

  getEvaluateTableau: `${dash_lens}/tableau_dashboard_search/get-evaluate_response/`,

  getEvaluateDataLens: `${data_lens}/data_lens/evaluate-response/`,

  //zoom api
  zoomRetreivePrompt: `${call_insights}/document_search/retrieve_from_transcribed_document`,
  zoomSessionHistory: `${call_insights}/document_session/get_doc_search_sessions`,
  zoomRenamesession: `${call_insights}/document_session/rename_doc_search_sessions`,
  zoomAudioHistroy: `${call_insights}/document_session/document_audio_history`,
  zoomAudioConvert: `${call_insights}}/Email/create_audio`,
  zoomVideoConvert: `${call_insights}/document_search/upload_local_video`,

  zoomChathistory: `${call_insights}/document_session/display_doc_search_session_chat`,
  zoomDecodeContent: `${call_insights}/document_search/convert_path_to_encoded_content`,

  tableauGetSessions: `${dash_lens}/tableau_dasboard_session/get_dashboard_search_sessions/`,

  getDatalensDropdownList: `${data_lens}/data_lens/get_dl_persona/`,

  getDropDownMedInsights: `${sentiment}/lang_trans_analytics/MedInsights/column_values/Column_and_unique_values_all/`,

  getAnswerSalesRepCopilot: `${co_pilot}/document_search/new_project`,

  getChatBotResponse: `${sentiment}/lang_trans_analytics/retrieve_from_dataset`,
  dropdownMedInsightsUniqueValue: `${sentiment}/lang_trans_analytics/MedInsights/column_values/Filtered_Column_and_unique_values/`,

  //Omnilens
  omnilensGeneratePrompt: `${omni_chatbot}/query_endpoint/main_query/`,
  omnilensSource: `${omni_chatbot}/query_endpoint/convert_path_to_encoded_content`,
  omnilensEditSession: `${omni_chatbot}/sessions_endpoint/rename_doc_search_sessions`,
  omnilensDocSearchSessions: `${omni_chatbot}/sessions_endpoint/get_doc_search_sessions`,
  omnilensDisplayDocSearchSessionChat: `${omni_chatbot}/sessions_endpoint/display_doc_search_session_chat`,

  //call insights 2
  uploadAudioCallInsights: `${call_insights_2}/audio_mapper/upload_local_audio`,
  extractedFiles: `${call_insights_2}/audio_mapper/extract-data/`,
  downloadData: `${call_insights_2}/audio_mapper/api/download-csv/`,
  queryCallInsights: `${call_insights_2}/audio_mapper/valid-query/`,
};

export const getUrl = {
  tables: `${dev}/login/list_tables/`,
  persona: `${dev}/login/list_persona/`,
  users: `${dev}/login/list_user/`,
  tableAccess: `${dev}/login/get-persona-data/`,
  // initialSetup: `${dev}/document_search/initial_setup/`,
  listCollection: `${dev}/login/list_collection/`,
  dashboardRegions: `${dash_lens}/dashboard_search/list_regions/`,
  listDashboards: `${dash_lens}/dashboard_search/list_dashboards/`,
  personauser: `${dev}/login/get_persona_tagged_users/`,
  getPersonaList: `${dev}/login/get-persona-list/`,
  getModelType: `${dev}/login/get_model_type/`,
  //pubmed
  get_disease_tagged: `${pubmed}/pubmed/get_disease_tagged/`,
  get_all_disease_tagged: `${pubmed}/pubmed/get_all_disease_tagged`,

  tableau_list_project: `${dash_lens}/tableau_dashboard_search/list_project/`,
  tableau_list_workbook: `${dash_lens}/tableau_dashboard_search/list_workbook/`,
  getWorkBookKpi: `${dev}/login/list_workbookkpi/`,
  getWorkBookTable: `${dev}/login/list_workbookkpi_table/`,
  geneInsGeData: `${geneInspector}/gene_inspector/get_data/`,
  downloadDocument: `${content_generation}/content_generator/download_docx/`,
  getDefaultSentiments: `${hcp_mlt}/lang_trans_analytics/default_sentiments/`,
  getDefaultSentimentsAnalysis: `${sentiment}/lang_trans_analytics/default_sentiments/`,
  //call insights 2
  getSource: `${call_insights_2}/audio_mapper/transcription/`,
  getDocumentList: `${document_generation}/content_generation/view`,
  downloadsession: `${data_lens}/data_lens/download_chat_session/`,
  documentArticleBack: `${document_generation}/content_generation/article_back_api`,
  documentDynamicBannerBack: `${document_generation}/content_generation/dynamic_banner_back_api`,
  documentRepTriggerEmailBack: `${document_generation}/content_generation/rep_trigger_email_back_api`,
  documentJournalPaperBack: `${document_generation}/content_generation/journal_paper_back_api`,
  documentGRDTableName: `${document_generation}/content_generation/display_tables`,
};

export const putUrl = {
  putPersona: `${dev}/login/edit_persona/`,
  putUser: `${dev}/login/edit_user/`,
  editCollection: `${dev}/login/edit_collection/`,
  editWorkBook: `${dev}/login/edit_workbook_kpi/`,
  editPersona: `${data_lens}/data_lens/edit_persona/`,
};

export const deleteUrl = {
  deletePersona: `${dev}/login/delete_persona/`,
  deleteUser: `${dev}/login/delete_user/`,
  deleteSession: `${data_lens}/data_lens/clear_sessions/`,
  deleteAllSession: `${data_lens}/data_lens/delete_entire_session/`,
  deletedocument: `${docSonar}/document_session/clear_doc_search_sessions/`,
  deleteAlldocument: `${docSonar}/document_session/Delete_entire_doc_search_session/`,
  deleteCollection: `${dev}/login/delete_collection`,
  deletedashboardsession: `${dash_lens}/dasboard_session/clear_dashboard_search_sessions/`,
  deleteAlldashboardsession: `${dash_lens}/dasboard_session/Delete_entire_dashboard_search_session/`,
  //pubmed
  delete_file_s3_pub: `${pubmed}/pubmed/delete_file_s3_pub/`,
  delete_disease_tagged_db: `${pubmed}/pubmed/delete_disease_tagged_db/`,
  delete_all_pubMedSessionHistory: `${pubmed}/document_session/Delete_entire_doc_search_session`,
  delete_pubMedSessionHistory: `${pubmed}/document_session/clear_doc_search_sessions`,

  deleteTableauSession: `${dash_lens}/tableau_dasboard_session/clear_dashboard_search_sessions/`,
  deleteTableauSessionAll: `${dash_lens}/tableau_dasboard_session/Delete_entire_dashboard_search_session/`,
  deleteAllSessioninDash: `${dash_lens}/text_to_dashboard/Delete_entire_dash_vista_session/`,
  deleteSessionIdinDash: `${dash_lens}/text_to_dashboard/clear_dash_vista_sessions/`,
  deleteWorkBookKpi: `${dev}/login/delete_workbook_kpi/`,
  deleteSingleFile: `${docSonar}/document_search/delete_single_file/`,
  deleteCollectionCt: `${contentTagging}/content_tagging/delete_collection_ct/`,

  dataDetecSessionId: `${data_detective}/session/clear_sessions/`,
  dataDetecSessionAll: `${data_detective}/session/delete_entire_session/`,

  comDetecSessionId: `${commercial}/session/clear_sessions/`,
  comDetecSessionAll: `${commercial}/session/delete_entire_session/`,

  ZoomDetecSessionId: `${call_insights}/document_session/clear_doc_search_sessions`,
  ZoomDetecSessionAll: `${call_insights}/document_session/Delete_entire_doc_search_session`,
  deleteOnePersona: `${data_lens}/data_lens/delete_persona/`,

  deleteMultipleCollection: `${dev}/login/delete_multiple_collection/`,

  //omnilens
  omnilensDeleteEntireDocSearchSession: `${omni_chatbot}/sessions_endpoint/Delete_entire_doc_search_session/`,
  omnilensClearDocSearchSession: `${omni_chatbot}/sessions_endpoint/clear_doc_search_sessions/`,
  documentContentDelete: `${document_generation}/content_generation/delete_content`,
};

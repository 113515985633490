import React, { useState, useEffect, useContext } from "react";
import { Table, Pagination } from "rsuite";
import "./index.css";
import { CommonLoader } from "../../common";
import { MyContext } from "../../context/ContextApi";
import { columnDataForMedPulseSenti } from "../../api/Postaction";
import { getDefaultSentimentDataAnalysis } from "../../api/GetActions";

function MedPulseSenti() {
  const {
    sentiMedLingoFileName,
    sentiMedLingoColumnValue,
    setSentiMedInsights,
    setSentiStoredFileNameMedpulse,
  } = useContext(MyContext);
  const { Column, HeaderCell, Cell } = Table;
  const [recordsSenti, setRecordsSenti] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [defaultSentiVal, setDefaultSentiVal] = useState();

  useEffect(() => {
    const sentimentData = async () => {
      setLoading(true);
      try {
        let res = await getDefaultSentimentDataAnalysis();
        if (res) {
          setDefaultSentiVal(res);
          let body = {
            stored_file_name: sentiMedLingoFileName,
            column_name: sentiMedLingoColumnValue,
            sentiments: res,
          };
          let res_1 = await columnDataForMedPulseSenti(body);
          setLoading(false);
          setRecordsSenti(res_1?.sent_df_data);
          setSentiStoredFileNameMedpulse(res_1?.stored_sen_file);
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    sentimentData();
  }, []);

  if (recordsSenti.length > 0) {
    setSentiMedInsights(true);
  }

  const tableData = defaultSentiVal
    ? Object.entries(defaultSentiVal).map(([sentiment, description]) => ({
        Sentiment: sentiment,
        Description: description,
      }))
    : [];

  const data = recordsSenti?.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const handleLimitChange = (newLimit) => {
    setTimeout(() => {
      setLimit(newLimit);
      setPage(1);
    }, 100);
  };

  const calculateRowHeight = (rowData) => {
    const baseHeight = 40;
    const lineHeight = 20;
    const maxLength = Math.max(
      ...columns?.map((col) => rowData?.[col]?.toString()?.length || 0)
    );
    const maxLines = Math.ceil(maxLength / 30) || 1;
    return baseHeight + (maxLines - 1) * lineHeight;
  };

  const CustomCell = (props) => (
    <Cell
      {...props}
      style={{
        padding: 15,
        whiteSpace: "normal",
        wordBreak: "break-word",
        overflowWrap: "break-word",
        lineHeight: "20px",
        overflowY: "auto",
      }}
    />
  );

  const columns = Object.keys(recordsSenti[0] || {});

  return (
    <>
      <div className="contentScroll">
        <div className="container">
          <div className="d-flex flex-column align-items-start">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <label className="midLingoLabel">
                      Med Pulse unveiling the emotional landscape of
                      pharmaceutical data through sentiment analysis
                    </label>
                    <div className="col-12">
                      {tableData && tableData.length > 0 ? (
                        <div
                          style={{
                            marginTop: "30px",
                            maxHeight: "300px",
                            overflowX: "auto",
                          }}
                        >
                          <table
                            style={{
                              marginTop: "20px",
                              width: "100%",
                              borderCollapse: "collapse",
                            }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                  }}
                                >
                                  Sentiment
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  Description
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.map((rowData, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                    }}
                                  >
                                    {rowData.Sentiment}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                    }}
                                  >
                                    {rowData.Description}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        "No data"
                      )}
                      {loading ? (
                        <CommonLoader />
                      ) : recordsSenti?.length > 0 ? (
                        <>
                          {/* <Table
                            className="custom-table-medPulse-senti"
                            virtualized
                            wordWrap="break-word"
                            hover={false}
                            height={400}
                            data={data}
                            style={{
                              scrollbarWidth: "0",
                              maxHeight: "50vh",
                              border: "1px solid #4C85F7",
                              marginTop: "40px",
                            }}
                            align="center"
                          >
                            {recordsSenti?.length > 0 &&
                              Object.keys(recordsSenti[0]).map((key, index) => (
                                <Column
                                  flexGrow={
                                    key === "S.No"
                                      ? 1
                                      : key.toLowerCase().includes("comments")
                                        ? 3
                                        : 2
                                  }
                                  verticalAlign="middle"
                                  key={key}
                                >
                                  <HeaderCell>{key}</HeaderCell>
                                  <Cell dataKey={key}>
                                    {(rowData, rowIndex) => rowData[key]}
                                  </Cell>
                                </Column>
                              ))}
                          </Table> */}
                          <Table
                            data={recordsSenti}
                            bordered
                            cellBordered
                            rowHeight={calculateRowHeight}
                            height={400}
                            autoHeight
                            style={{
                              wordWrap: "break-word",
                              whiteSpace: "normal",
                              overflowX: "auto",
                              marginTop: "40px",
                            }}
                          >
                            {columns.map((column, index) => (
                              <Column key={index} width={200} flexGrow={1}>
                                <HeaderCell>{column}</HeaderCell>
                                <CustomCell dataKey={column} />
                              </Column>
                            ))}
                          </Table>
                          {/* <div style={{ paddingTop: 20 }}>
                            <>
                              <Pagination
                                className="table-container-medPulse"
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                maxButtons={5}
                                size="xs"
                                layout={["total", "-", "pager", "skip"]}
                                total={recordsSenti.length}
                                limitOptions={[10, 30, 50]}
                                limit={limit}
                                activePage={page}
                                onChangePage={setPage}
                                onChangeLimit={handleLimitChange}
                              />
                            </>
                          </div> */}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MedPulseSenti;

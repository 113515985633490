import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { TfiArrowCircleLeft, TfiArrowCircleRight } from "react-icons/tfi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import CommonBtn from "../commonBtn";
import CommonClearBtn from "../commonClearBtn";
import CommonInput from "../commonInput";
import CommonModal from "../commonModal";
import CommonTextArea from "../commonTextArea";
import SideBarLogout from "../sideBarLogout";
import SidebarManagerItem from "../sideBarManagerItem";
import SidBarPubmedFilter from "../sideBarPubmedFilter";
import {
  dashboardSessionHistory,
  deleteAllsessions,
  deleteDashboardAll,
  deleteDashboardID,
  deleteDocumentAll,
  deleteDocumentID,
  deleteSessionid,
  fetchGeneData,
  tableauGetSessions,
  tableauRenameSession,
  uploadDocGen,
  deleteAllSessionDash,
  deleteDashSessionid,
  dataDectectiveRenameApi,
  comDectectiveRenameApi,
  omnilensEditSessionData,
  omnilensDocSearchSessionsData,
  documentCollectionLastUsedData,
  sessionHistoryPubMedData,
  editSessionHistoryPubMedData,
} from "../../api/Postaction";
import {
  comDetectDeleteSession,
  comDetectiveDeleteAll,
  delete_all_pubMedSessionHistoryData,
  delete_pubMed_SessionHistoryData,
  deleteDataDeleteSession,
  deleteDataDetectiveAll,
  deleteTableauSessionAll,
  deleteTableauSessionId,
  deleteZoomDeleteSession,
  deleteZoomSeesionAll,
  omnilensClearDocSearchSessionData,
  omnilensDeleteEntireDocSearchSessionData,
} from "../../api/DeleteAction";
import { postUrl } from "../../api/Endpoint";
import { MyContext } from "../../context/ContextApi";
import { commonStr } from "../../utils/constants/commonStrings";
import { errorStr } from "../../utils/constants/errorStrings";
import {
  sourchFilterData,
  relevanceData,
  soryByData,
} from "../../utils/constants/constantData";
import {
  getRandomNumberFromUuid,
  showToast,
} from "../../utils/helpers/helpers";
import axiosApi from "../../interceptors/axiosinterceptor";
import { TfiMenuAlt } from "react-icons/tfi";
import "./Sidebar.css";
import SidebarCurrentSession from "../sidebarCurrentSession";
import SidebarSessionHistory from "../sidebarSessionHistory";
import Faq from "../faq/Faq";
import { SessionDownload } from "../../api/GetActions";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { messageIcon, messageIcon1 } from "../../utils/images";
import { TiTick } from "react-icons/ti";
import { BiEdit } from "react-icons/bi";
import { IoIosClose } from "react-icons/io";

const { logoutTitle, areYouWant, deleteSessionTitle, ques, clearAllHistory } =
  commonStr;

const SidebarComponent = () => {
  const {
    sessionUpdate,
    dashboardname,
    setDashboardname,
    filterContent,
    allValues,
    setNewQuery,
    currentSession,
    setCurretSession,
    setFilterContent,
    sessionPersonaVal,
    setSessionPersonaval,
    setDropDownPersona,
    setIsTableVisible,
    setIsDiseaseListShow,
    startLoading,
    setGeneTableClear,
    personaValueList,
    setIsSubmitGeneIns,
    setIsApiCallLength,
    setDetectivePersoanVal,
    detectivePersonaVal,
    setComPersoanVal,
    comPersonaVal,
    zoomCollValue,
    setIsSideBarOpen,
    personValuePubMed,
    setPersonValuePubMed,
    allResponses,
    setAllResponses,
  } = useContext(MyContext);

  const email = sessionStorage.getItem("email");
  const { id } = useParams();

  const { instance } = useMsal();

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  let activeSession = currentPath.substring(1);

 
  if (activeSession.includes("%20")) {
    activeSession = activeSession.replace("%20", " ");
  }
  let path = activeSession.split("/")[0];

  const [currentIndex, setCurrentIndex] = useState("");
  const [oldValue, setOldValue] = useState("");
  const [initialValue, setInitialValue] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [isTablet, setIsTablet] = useState(false);
  const [expand, setExpand] = useState(false);
  const [collectionValue, setCollectionValue] = useState([]);
  const [sessionslist, setSessionList] = useState([]);
  const [documentsessionslist, setDocumentsessionslist] = useState([]);
  const [omnilenssessionslist, setOmnilensSessionslist] = useState([]);
  const [pubMedsessionslist, setPubMedSessionslist] = useState([]);
  const [dashboardsessionslist, setDashboardsessionslist] = useState([]);
  const [logoutModal, setLogoutModal] = useState(false);
  const [list, setList] = useState(false);
  const [documentlist, setDocumentList] = useState(false);
  const [omnilenslist, setOmnilensList] = useState(false);
  const [pubMedlist, setPubMedList] = useState(false);
  const [dashboardlist, setDashboardList] = useState(false);
  const [reload, setReload] = useState(false);
  const [documentreload, setDocumentReload] = useState(false);
  const [omnilensreload, setOmnilensReload] = useState(false);
  const [pubMedreload, setPubMedReload] = useState(false);
  const [dashboardreload, setDashboardReload] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteSession, setDeleteSession] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [editSession, setEditSession] = useState(false);
  const [dashSessionList, setDashSessionList] = useState([]);
  const [dashList, setDashList] = useState(false);
  const [dashReload, setDashReload] = useState(false);
  const [isLoaderSession, setIsLoderSession] = useState(false);
  const [tableauSessionslist, setTableauSessionslist] = useState([]);
  const [tableauReload, setTableauReload] = useState(false);
  const [isDeleteSessionAll, setIsDeleteSessionAll] = useState(false);
  const [isGeneInspData, setIsGenInspData] = useState({
    geneTextArea: "",
    geneFileInput: "",
    geneDiesesInput: "",
    genPublicationType: "",
    genSortByType: "best_match",
  });

  const [content, setContent] = useState();
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isFileDisableBtn, setIsFileDisableBtn] = useState(false);
  const [isTextDisableBtn, setIsTexteDisableBtn] = useState(false);
  const [fileInputKey, setFileInputKey] = useState(0);
  const [isSubBtnDisable, setIsSubBtnDisable] = useState(false);
  const [isDuplicateModal, setIsDuplicateModal] = useState(false);
  const [isDuplicateList, setIsDuplicateList] = useState([]);

  const [dataDetectiveList, setDataDetectiveList] = useState(false);
  const [dataDecSessionList, setDataDecSessionList] = useState([]);
  const [dataDectreload, setDataDectreload] = useState(false);

  const [zoomList, setZoomList] = useState(false);
  const [zoomSessionslist, setZoomSessionsList] = useState([]);
  const [zoomReoload, setZoomReload] = useState(false);

  const [comDetectiveList, setComDetectiveList] = useState(false);
  const [comDecSessionList, setComDecSessionList] = useState([]);
  const [comDectreload, setComDectreload] = useState(false);

  const [faqVisibleModal, setFaqVisibleModal] = useState(false);

  const fileInputRef = useRef(null);

  let fileReader;

  const activeContentUrl = currentPath.startsWith("/contentChange");
  const activeGeneInUrl = currentPath.startsWith("/genInspector");
  const activeAdminUrl = currentPath.startsWith("/admin");
  const activePubmedUrl = currentPath.startsWith("/pubmedquery");
  const activeGeneInsUrl = currentPath.startsWith("/genInspector");
  const activeNewQryUrl = currentPath.startsWith("/newquery");
  const activeTxtChartUrl = currentPath.startsWith("/textchartquery");
  const activeDocumentUrl = currentPath.startsWith("/documentquery");
  const activeSeeFilesUrl = currentPath.startsWith("/seeFiles");
  const activeTableauUrl = currentPath.startsWith("/tableauquery");
  const activeDashboardUrl = currentPath.startsWith("/dashboardquery");
  const activeContentGenUrl = currentPath.startsWith("/contentGeneration");
  const activefileUploadingUrl = currentPath.startsWith("/fileUploading");
  const activeSentimentAnalysisUrl =
    currentPath.startsWith("/sentimentAnalysis");
  const activeMedLingoUrlSenti = currentPath.startsWith("/sentiMedLingo");
  const activeMedPulseUrlSenti = currentPath.startsWith("/sentiMedPulse");
  const activeMedInsightsUrlSenti = currentPath.startsWith("/sentiMedInsights");
  const activeMedLingoUrl = currentPath.startsWith("/medLingo");
  const activeMedPulseUrl = currentPath.startsWith("/medPulse");
  const activeMedInsightsUrl = currentPath.startsWith("/medInsights");
  const activeMlrUrl = currentPath.startsWith("/mlrReview");
  const activePersonalIdentityUrl = currentPath.startsWith(
    "/personalInfoIdentity"
  );
  const activeWaterMarkUrl = currentPath.startsWith("/waterMarkDetection");
  const activePersonalIdentityImageUrl = currentPath.startsWith(
    "/personalInfoIdentityInImage"
  );
  const activeEmergencySolution = currentPath.startsWith("/salesRepCopilot");

  const activeDataDetectiveUrl = currentPath.startsWith("/dataDetective");
  const activeZoomUrl = currentPath.startsWith("/zoom");
  const activeComDetectiveUrl = currentPath.startsWith("/comdataDetective");
  const activeOmnilensUrl = currentPath.startsWith("/omnilens");
  const activeCallInsights_2 = currentPath.startsWith("/callInsights_2");
  const activePromptExtractFilesPage = currentPath.startsWith(
    "/promptExtractFilesPage"
  );

  //document generation
  const activeDocumentGeneration = currentPath.startsWith(
    "/documentGeneration"
  );
  const activeDocumentStructure = currentPath.startsWith("/documentStructure");
  const activeDocumentHeading = currentPath.startsWith("/documentHeading");
  const activeDocumentEditor = currentPath.startsWith("/documentEditor");
  const activeDocumentFileExtract = currentPath.startsWith(
    "/documentFileExtract"
  );

  const [selectedFileName, setSelectedFileName] = useState("No file chosen");

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth <= 1024);
      if (window.innerWidth <= 1024) {
        setIsOpen(false);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //initial clear sidebar
  useEffect(() => {
    setIsGenInspData({
      geneTextArea: "",
      geneFileInput: "",
      geneDiesesInput: "",
      genPublicationType: "",
      genSortByType: "best_match",
    });
    setIsSubBtnDisable(false);
    setIsTexteDisableBtn(false);
    setIsFileDisableBtn(false);
    setSelectedFileName("No file chosen");
  }, [activeGeneInUrl]);

  useEffect(() => {
    setCurrentIndex();
    setEditSession(false);
  }, [path, id]);

  useEffect(() => {
    if (activeSession.startsWith("tableauquery") && !id) {
      setTableauSessionslist([]);
    }
    if (activeSession.startsWith("dashboardquery") && !id) {
      setDashboardsessionslist([]);
    }
  }, [activeSession, id]);

  useEffect(() => {
    if (currentSession.length > 0) {
      sessionslist.push(currentSession);
    }
  }, [currentSession]);

  const handleSessionNameChange = () => {
    setEditSession(true);
    if (activeSession.startsWith("newquery")) {
      let indexValue = sessionslist.indexOf(currentSession);
      setCurrentIndex(indexValue);
    }
    if (activeSession.startsWith("textchartquery")) {
      let indexValue = dashSessionList.indexOf(currentSession);
      setCurrentIndex(indexValue);
    }
    if (
      activeSession.startsWith("documentquery") ||
      activeSession.startsWith("seeFiles")
    ) {
      let indexValue = documentsessionslist.indexOf(currentSession);
      setCurrentIndex(indexValue);
    }
    if (activeSession.startsWith("omnilens")) {
      let indexValue = omnilenssessionslist.indexOf(currentSession);
      setCurrentIndex(indexValue);
    }
    if (activeSession.startsWith("pubmedquery")) {
      let indexValue = pubMedsessionslist.indexOf(currentSession);
      setCurrentIndex(indexValue);
    }
    if (activeSession.startsWith("dataDetective")) {
      let indexValue = dataDecSessionList.indexOf(currentSession);
      setCurrentIndex(indexValue);
    }

    if (activeSession.startsWith("comdataDetective")) {
      let indexValue = comDecSessionList.indexOf(currentSession);
      setCurrentIndex(indexValue);
    }

    if (activeSession.startsWith("zoom")) {
      let indexValue = zoomSessionslist.indexOf(currentSession);
      setCurrentIndex(indexValue);
    }

    if (activeSession.startsWith("dashboardquery")) {
      let indexValue = dashboardsessionslist.findIndex(
        (obj) => obj["session name"] === currentSession
      );
      setCurrentIndex(indexValue);
      setOldValue(currentSession);
    }

    if (activeSession.startsWith("tableauquery")) {
      let indexValue = tableauSessionslist.findIndex(
        (obj) => obj["session name"] === currentSession
      );
      setCurrentIndex(indexValue);
      setOldValue(currentSession);
    }
  };

  const handleInputChange = (e) => {
    if (activeSession.startsWith("newquery")) {
      const newEditedItems = [...sessionslist];
      setOldValue(initialValue[currentIndex]);
      newEditedItems[currentIndex] = e.target.value;
      setSessionList(newEditedItems);
    }
    if (activeSession.startsWith("textchartquery")) {
      const newEditedItems = [...dashSessionList];
      setOldValue(initialValue[currentIndex]);
      newEditedItems[currentIndex] = e.target.value;
      setDashSessionList(newEditedItems);
    }
    if (
      activeSession.startsWith("documentquery") ||
      activeSession.startsWith("seeFiles")
    ) {
      const newEditedItems = [...documentsessionslist];
      setOldValue(initialValue[currentIndex]);
      newEditedItems[currentIndex] = e.target.value;
      setDocumentsessionslist(newEditedItems);
    }
    if (activeSession.startsWith("omnilens")) {
      const newEditedItems = [...omnilenssessionslist];
      setOldValue(initialValue[currentIndex]);
      newEditedItems[currentIndex] = e.target.value;
      setOmnilensSessionslist(newEditedItems);
    }
    if (activeSession.startsWith("pubmedquery")) {
      const newEditedItems = [...pubMedsessionslist];
      setOldValue(initialValue[currentIndex]);
      newEditedItems[currentIndex] = e.target.value;
      setPubMedSessionslist(newEditedItems);
    }
    if (activeSession.startsWith("dataDetective")) {
      const newEditedItems = [...dataDecSessionList];
      setOldValue(initialValue[currentIndex]);
      newEditedItems[currentIndex] = e.target.value;
      setDataDecSessionList(newEditedItems);
    }
    if (activeSession.startsWith("comdataDetective")) {
      const newEditedItems = [...comDecSessionList];
      setOldValue(initialValue[currentIndex]);
      newEditedItems[currentIndex] = e.target.value;
      setComDecSessionList(newEditedItems);
    }
    if (activeSession.startsWith("zoom")) {
      const newEditedItems = [...zoomSessionslist];
      setOldValue(initialValue[currentIndex]);
      newEditedItems[currentIndex] = e.target.value;
      setZoomSessionsList(newEditedItems);
    }

    if (activeSession.startsWith("dashboardquery")) {
      let newEditedItems = [...dashboardsessionslist];
      newEditedItems[currentIndex]["session name"] = e.target.value;
      setDashboardsessionslist(newEditedItems);
    }
    if (activeSession.startsWith("tableauquery")) {
      let newEditedItems = [...tableauSessionslist];
      newEditedItems[currentIndex]["session name"] = e.target.value;
      setTableauSessionslist(newEditedItems);
    }
  };

  const handleSaveClick = async () => {
    if (activeSession.startsWith("newquery")) {
      const updatedItems = [...sessionslist];

      updatedItems[currentIndex] = sessionslist[currentIndex];
      if (updatedItems[currentIndex] !== "") {
        try {
          let res = await axiosApi.post(`${postUrl.dataRenamesession}`, {
            email_id: email,
            session_id: oldValue,
            new_session_id: updatedItems[currentIndex],
            persona_name: sessionPersonaVal,
          });
          setCurrentIndex();
          setReload((c) => !c);
          setCurretSession(updatedItems[currentIndex]);
          navigate(`/newquery/${updatedItems[currentIndex]}`);
        } catch (err) {
          console.log("Error in Renaming session Name", err);
        }
      }
    }
    if (activeSession.startsWith("textchartquery")) {
      const updatedItems = [...dashSessionList];

      updatedItems[currentIndex] = dashSessionList[currentIndex];
      if (updatedItems[currentIndex] !== "") {
        try {
          let res = await axiosApi.post(`${postUrl.dashRenameSession}`, {
            email_id: email,
            session_id: oldValue,
            new_session_id: updatedItems[currentIndex],
            persona: personaValueList.label,
          });
          setCurrentIndex();
          setDashReload((c) => !c);
          setCurretSession(updatedItems[currentIndex]);
          navigate(`/textchartquery/${updatedItems[currentIndex]}`);
        } catch (err) {
          console.log("Error in Renaming session Name", err);
        }
      }
    }
    if (
      activeSession.startsWith("documentquery") ||
      activeSession.startsWith("seeFiles")
    ) {
      const updatedItems = [...documentsessionslist];
      updatedItems[currentIndex] = documentsessionslist[currentIndex];
      if (updatedItems[currentIndex] !== "") {
        try {
          let res = await axiosApi.post(`${postUrl.documentRenamesession}`, {
            email_id: email,
            session_id: oldValue,
            new_session_id: updatedItems[currentIndex],
            collection_name: allValues.collection,
          });
          setCurrentIndex();
          setDocumentReload((c) => !c);
          setCurretSession(updatedItems[currentIndex]);
          navigate(`/documentquery/${updatedItems[currentIndex]}`);
        } catch (err) {
          console.log("Error in Renaming session Name", err);
        }
      }
    }

    if (activeSession.startsWith("omnilens")) {
      const updatedItems = [...omnilenssessionslist];
      updatedItems[currentIndex] = omnilenssessionslist[currentIndex];
      if (updatedItems[currentIndex] !== "") {
        let body = {
          email_id: email,
          session_id: oldValue,
          new_session_id: updatedItems[currentIndex],
        };
        try {
          let res = await omnilensEditSessionData(body);
          setCurrentIndex();
          setOmnilensReload((c) => !c);
          setCurretSession(updatedItems[currentIndex]);
          // navigate(`/omnilens/${updatedItems[currentIndex]}`);
        } catch (err) {
          console.log("Error in Renaming session Name", err);
        }
      }
    }

    if (activeSession.startsWith("pubmedquery")) {
      const updatedItems = [...pubMedsessionslist];
      updatedItems[currentIndex] = pubMedsessionslist[currentIndex];
      if (updatedItems[currentIndex] !== "") {
        let body = {
          email_id: email,
          session_id: oldValue,
          new_session_id: updatedItems[currentIndex],
        };
        try {
          await editSessionHistoryPubMedData(body);
          setCurrentIndex();
          setPubMedReload((c) => !c);
          setCurretSession(updatedItems[currentIndex]);
          // navigate(`/pubmedquery/${updatedItems[currentIndex]}`);
        } catch (err) {
          console.log(err);
        }
      }
    }

    if (activeSession.startsWith("zoom")) {
      const updatedItems = [...zoomSessionslist];
      updatedItems[currentIndex] = zoomSessionslist[currentIndex];
      if (updatedItems[currentIndex] !== "") {
        try {
          let res = await axiosApi.post(`${postUrl.zoomRenamesession}`, {
            email_id: email,
            session_id: oldValue,
            new_session_id: updatedItems[currentIndex],
            collection_name: zoomCollValue,
          });
          setCurrentIndex();
          setZoomReload((c) => !c);
          setCurretSession(updatedItems[currentIndex]);
          navigate(`/zoom/${updatedItems[currentIndex]}`);
        } catch (err) {
          console.log("Error in Renaming session Name", err);
        }
      }
    }

    if (activeSession.startsWith("dataDetective")) {
      const updatedItems = [...dataDecSessionList];
      updatedItems[currentIndex] = dataDecSessionList[currentIndex];
      if (updatedItems[currentIndex] !== "") {
        try {
          let payload = {
            email_id: email,
            session_id: oldValue,
            new_session_id: updatedItems[currentIndex],
            persona_name: detectivePersonaVal,
          };
          let renameStatus = await dataDectectiveRenameApi(payload);
          if (renameStatus?.status === 200) {
            setCurrentIndex();
            setDataDectreload((c) => !c);
            setCurretSession(updatedItems[currentIndex]);
            navigate(`/dataDetective/${updatedItems[currentIndex]}`);
          }
        } catch (err) {
          console.log("Error in Renaming session Name", err);
        }
      }
    }

    //newchanges
    if (activeSession.startsWith("comdataDetective")) {
      const updatedItems = [...comDecSessionList];
      updatedItems[currentIndex] = comDecSessionList[currentIndex];
      if (updatedItems[currentIndex] !== "") {
        try {
          let payload = {
            email_id: email,
            session_id: oldValue,
            new_session_id: updatedItems[currentIndex],
            persona_name: comPersonaVal,
          };
          let renameStatus = await comDectectiveRenameApi(payload);
          if (renameStatus?.status === 200) {
            setCurrentIndex();
            setComDectreload((c) => !c);
            setCurretSession(updatedItems[currentIndex]);
            navigate(`/comdataDetective/${updatedItems[currentIndex]}`);
          }
        } catch (err) {
          console.log("Error in Renaming session Name", err);
        }
      }
    }

    if (activeSession.startsWith("dashboardquery")) {
      const updatedItems = [...dashboardsessionslist];
      updatedItems[currentIndex] = dashboardsessionslist[currentIndex];
      if (updatedItems[currentIndex] !== "") {
        try {
          let res = await axiosApi.post(`${postUrl.dashboardRenamesession}`, {
            email_id: email,
            session_id: oldValue,
            new_session_id: updatedItems[currentIndex]["session name"],
            dashboard_name: dashboardname.name,
          });
          setCurrentIndex();
          setDashboardReload((c) => !c);
          setCurretSession(updatedItems[currentIndex]["session name"]);
          navigate(
            `/dashboardquery/${updatedItems[currentIndex]["session name"]}`
          );
          // }
        } catch (err) {
          console.log("Error in Dashboard Renaming session Name", err);
        }
      }
    }
    if (activeSession.startsWith("tableauquery")) {
      const updatedItems = [...tableauSessionslist];
      updatedItems[currentIndex] = tableauSessionslist[currentIndex];
      if (updatedItems[currentIndex] !== "") {
        let payload = {
          email_id: email,
          session_id: oldValue,
          new_session_id: updatedItems[currentIndex]["session name"],
          workbook_name: dashboardname.name,
        };
        try {
          await tableauRenameSession(payload);
          setCurrentIndex();
          setTableauReload((c) => !c);
          setCurretSession(updatedItems[currentIndex]["session name"]);
          navigate(
            `/tableauquery/${updatedItems[currentIndex]["session name"]}`
          );
        } catch (err) {
          console.log("Error in Dashboard Renaming session Name", err);
        }
      }
    }

    setEditSession(false);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
    setIsDeleteSessionAll(false);
    setDeleteSession();
  };

  const handleDeleteSession = (data) => {
    setDeleteSession(data);
    setDeleteModal(true);
    setIsDeleteSessionAll(false);
  };

  const handleToDownloadSession = async (e) => {
    const requestData = {
      email_id: email,
      session_id: e,
      persona_name: sessionPersonaVal,
    };

    try {
      const response = await SessionDownload(
        requestData.email_id,
        requestData.session_id,
        requestData.persona_name
      );
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(new Blob([response.data]));

      link.href = url;
      link.setAttribute("download", "chatsession.pdf");

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);

      console.log("Session Downloaded Successfully");
    } catch (error) {
      if (error.response && error.response.data) {
        console.error("API Error Response:", error.response.data);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const handleDeleteHistory = () => {
    setIsDeleteSessionAll(true);
    setDeleteModal(true);
  };

  const deleteAllHistory = async () => {
    if (
      !currentPath.includes("userManagement") &&
      !currentPath.includes("help")
    ) {
      if (activeSession.startsWith("newquery")) {
        if (sessionslist.length > 0) {
          await deleteAllsessions(email, sessionPersonaVal);
          setReload((current) => !current);
          navigate("/newquery");
        }
      }
      if (activeSession.startsWith("textchartquery")) {
        if (dashSessionList.length > 0) {
          await deleteAllSessionDash(email, personaValueList.label);
          setDashReload((current) => !current);
          navigate("/textchartquery");
        }
      }
      if (activeSession.startsWith("documentquery")) {
        if (documentsessionslist.length > 0) {
          let body = {
            email: email,
            collection_name: allValues.collection,
          };
          await deleteDocumentAll(body);
          setDocumentReload((current) => !current);
          navigate("/documentquery");
        }
      }

      if (activeSession.startsWith("omnilens")) {
        let body = {
          email: email,
        };
        if (omnilenssessionslist.length > 0) {
          await omnilensDeleteEntireDocSearchSessionData(body);
          setOmnilensReload((current) => !current);
          navigate("/omnilens");
        }
      }

      if (activeSession.startsWith("pubmedquery")) {
        let body = {
          email: email,
        };
        if (pubMedsessionslist?.length > 0) {
          await delete_all_pubMedSessionHistoryData(body);
          pubMedSessionHistory();
          navigate("/pubmedquery");
        }
      }

      if (activeSession.startsWith("dataDetective")) {
        if (dataDecSessionList?.length > 0) {
          let body = {
            email: email,
            persona_name: detectivePersonaVal,
          };
          await deleteDataDetectiveAll(body);
          setDataDectreload((current) => !current);
          setCurretSession("");
          navigate("/dataDetective");
        }
      }

      if (activeSession.startsWith("comdataDetective")) {
        if (comDecSessionList?.length > 0) {
          let body = {
            email: email,
            persona_name: comPersonaVal,
          };
          await comDetectiveDeleteAll(body);
          setComDectreload((current) => !current);
          setCurretSession("");
          navigate("/comdataDetective");
        }
      }

      if (activeSession.startsWith("zoom")) {
        if (zoomSessionslist.length > 0) {
          let body = {
            email: email,
            collection_name: zoomCollValue,
          };
          await deleteZoomSeesionAll(body);
          setZoomReload((current) => !current);
          navigate("/zoom");
        }
      }

      if (activeSession.startsWith("dashboardquery")) {
        if (dashboardsessionslist.length > 0) {
          await deleteDashboardAll(email, dashboardname.name);
          setDashboardReload((current) => !current);
          navigate("/dashboardquery");
        }
      }
      if (activeSession.startsWith("tableauquery")) {
        if (tableauSessionslist.length > 0) {
          await deleteTableauSessionAll(email, dashboardname.name);
          setTableauReload((current) => !current);
          navigate("/tableauquery");
        }
      }
      setDeleteModal(false);
      setIsDeleteSessionAll(false);
    }
  };

  const excistingSession = async () => {
    if (currentPath.startsWith("/newquery") && sessionPersonaVal) {
      setList(true);
      try {
        let res = await axiosApi.post(`${postUrl.sessionlist}`, {
          email_id: email,
          persona_name: sessionPersonaVal,
        });

        if (res.status === 200) {
          setSessionList(res.data);
          setInitialValue(res.data);
          setList(false);
          setCurrentIndex();
          setEditSession(false);
        } else {
          showToast(errorStr.noSeesion);
          setSessionList([]);
          setInitialValue([]);
          setList(false);
        }
      } catch (Err) {
        console.log("Error in getting Session List", Err);
      }
    }
  };
  const excistingDashSession = async () => {
    if (currentPath.startsWith("/textchartquery") && personaValueList) {
      setDashList(true);
      try {
        let res = await axiosApi.post(`${postUrl.chartSessionHistory}`, {
          email_id: email,
          persona: personaValueList.label,
        });

        if (res.status === 200) {
          setDashSessionList(res.data);
          setInitialValue(res.data);
          setDashList(false);
          setCurrentIndex();
          setEditSession(false);
        } else {
          showToast(errorStr.noSeesion);
          setDashSessionList([]);
          setInitialValue([]);
          setDashList(false);
        }
      } catch (Err) {
        console.log("Error in getting Session List", Err);
      }
    }
  };

  const excistingDocuments = async (getValue = allValues?.collection) => {
    if (
      (currentPath.startsWith("/documentquery") && allValues !== undefined) ||
      (currentPath.startsWith("/seeFiles") && sessionPersonaVal)
    ) {
      setDocumentList(true);
      try {
        let res = await axiosApi.post(`${postUrl.documentSessionHistory}`, {
          email_id: email,
          // collection_name: allValues?.collection,
          collection_name: getValue,
        });
        let body = {
          collection_name: getValue,
          last_used_by: email,
        };
        let res_1 = await documentCollectionLastUsedData(body);
        setDocumentList(false);
        setDocumentsessionslist(res?.data);
        setInitialValue(res?.data);
        setCurrentIndex();
        setEditSession(false);
      } catch (Err) {
        console.log("Error in getting Session List", Err);
      }
    }
  };

  const existingOmniSessionHistory = async () => {
    if (currentPath.startsWith("/omnilens")) {
      setOmnilensList(true);
      let body = {
        email_id: email,
      };
      try {
        let res = await omnilensDocSearchSessionsData(body);
        setOmnilensList(false);
        setOmnilensSessionslist(res);
        setInitialValue(res);
        setCurrentIndex();
        setEditSession(false);
      } catch (err) {
        console.log("Error");
      }
    }
  };

  const excistingZoomHistory = async () => {
    if (currentPath.startsWith("/zoom") && zoomCollValue) {
      setZoomList(true);
      try {
        let res = await axiosApi.post(`${postUrl.zoomSessionHistory}`, {
          email_id: email,
          collection_name: zoomCollValue,
        });
        console.log("res", JSON.stringify(res.data));
        setZoomList(false);
        setZoomSessionsList(res?.data);
        setInitialValue(res?.data);
        setCurrentIndex();
        setEditSession(false);
      } catch (Err) {
        console.log("Error in getting Session List", Err);
      }
    }
  };

  const excistingDataDetSession = async () => {
    if (currentPath.startsWith("/dataDetective") && detectivePersonaVal) {
      setDataDetectiveList(true);
      try {
        let res = await axiosApi.post(`${postUrl.dataDetectiveSession}`, {
          email_id: email,
          persona_name: detectivePersonaVal,
        });
        if (res) {
          setDataDetectiveList(false);
          setDataDecSessionList(res?.data);
          setInitialValue(res?.data);
          setCurrentIndex();
          setEditSession(false);
        }
      } catch (Err) {
        console.log("Error in getting Session List", Err);
      }
    }
  };

  const existingComDetectApi = async () => {
    if (currentPath.startsWith("/comdataDetective") && comPersonaVal) {
      setComDetectiveList(true);
      try {
        let res = await axiosApi.post(`${postUrl.comDetectiveSession}`, {
          email_id: email,
          persona_name: comPersonaVal,
        });
        if (res) {
          setComDetectiveList(false);
          setComDecSessionList(res?.data);
          setInitialValue(res?.data);
          setCurrentIndex();
          setEditSession(false);
        }
      } catch (Err) {
        console.log("Error in getting Session List", Err);
      }
    }
  };

  const excistingDashboardSession = async () => {
    setDashboardsessionslist([]);
    if (dashboardname.name !== "") {
      setDashboardList(true);
      let body = {
        email_id: email,
        dashboard_name: dashboardname?.name,
        region_name: dashboardname?.region,
        account_id: "919490798061",
      };
      let a = await dashboardSessionHistory(body);
      setDashboardList(false);
      setDashboardsessionslist(a);
      setInitialValue(a);
    }
  };

  useEffect(() => {
    if (
      !currentPath.includes("userManagement") &&
      !currentPath.includes("help")
    ) {
      if (activeSession.startsWith("newquery")) {
        setDashboardname({
          name: "",
          region: "",
        });
        excistingSession();
        setExpand(false);
      }
    }
  }, [reload, sessionUpdate, activeSession, sessionPersonaVal]);

  useEffect(() => {
    if (
      !currentPath.includes("userManagement") &&
      !currentPath.includes("help")
    ) {
      if (activeSession.startsWith("textchartquery")) {
        setDashboardname({
          name: "",
          region: "",
        });
        excistingDashSession();
        setExpand(false);
      }
    }
  }, [dashReload, sessionUpdate, personaValueList]);

  useEffect(() => {
    if (currentPath.includes("seeFiles")) {
      excistingDocuments(sessionPersonaVal);
      setCurretSession("");
    }
  }, [sessionPersonaVal, currentPath]);

  useEffect(() => {
    if (
      !currentPath.includes("userManagement") &&
      !currentPath.includes("files")
    ) {
      if (activeSession.startsWith("documentquery")) {
        setDashboardname({
          name: "",
          region: "",
        });
        excistingDocuments();

        setExpand(false);
      }
    }
  }, [
    documentreload,
    collectionValue,
    sessionUpdate,
    allValues,
    // sessionPersonaVal,
    // activeSession,
  ]);

  useEffect(() => {
    if (
      !currentPath.includes("userManagement") &&
      !currentPath.includes("files")
    ) {
      if (activeSession.startsWith("omnilens")) {
        // setDashboardname({
        //   name: "",
        //   region: "",
        // });
        existingOmniSessionHistory();
        // setExpand(false)
      }
    }
  }, [omnilensreload, sessionUpdate, currentPath]);

  useEffect(() => {
    if (activeSession.startsWith("dataDetective")) {
      excistingDataDetSession();
      setExpand(false);
    }
  }, [dataDectreload, sessionUpdate, path, detectivePersonaVal]);

  useEffect(() => {
    if (activeSession.startsWith("comdataDetective")) {
      existingComDetectApi();
      setExpand(false);
    }
  }, [comDectreload, sessionUpdate, path, comPersonaVal]);

  useEffect(() => {
    if (activeSession.startsWith("zoom")) {
      excistingZoomHistory();
      setExpand(false);
    }
  }, [zoomReoload, sessionUpdate, path, zoomCollValue]);

  useEffect(() => {
    if (
      !currentPath.includes("userManagement") &&
      !currentPath.includes("help")
    ) {
      if (activeSession.startsWith("dashboardquery")) {
        excistingDashboardSession();
        setExpand(false);
      }
    }
  }, [dashboardreload, sessionUpdate]);

  const getDashboardTablueSession = async () => {
    setTableauSessionslist([]);
    if (dashboardname.name !== "") {
      setIsLoderSession(true);
      let body = {
        email_id: email,
        workbook_name: dashboardname?.name,
        project_name: dashboardname?.region,
        account_id: "919490798061",
      };
      let a = await tableauGetSessions(body);
      setIsLoderSession(false);
      setTableauSessionslist(a);
      setInitialValue(a);
    }
  };

  const pubMedSessionHistory = async () => {
    if (currentPath.startsWith("/pubmedquery")) {
      setPubMedList(true);
      let body = {
        email_id: email,
      };
      try {
        let res = await sessionHistoryPubMedData(body);
        setPubMedList(false);
        setPubMedSessionslist(res.data);
        setInitialValue(res.data);
        setCurrentIndex();
        setEditSession(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (activeSession.startsWith("tableauquery")) {
      getDashboardTablueSession();
      setExpand(false);
    }
  }, [tableauReload, sessionUpdate]);

  useEffect(() => {
    if (currentPath.startsWith("/pubmedquery")) {
      setDashboardname({
        name: "",
        region: "",
      });
      pubMedSessionHistory();
    }
  }, [activeSession]);

  const handleLogout = () => {
    instance.logout();
    setSessionPersonaval("");
    setDetectivePersoanVal("");
    setComPersoanVal("");
    setDropDownPersona("");
    setIsTableVisible(false);
    setIsDiseaseListShow(false);
    sessionStorage.clear();
    // navigate("/");
  };

  const finalDelete = async () => {
    setDeleteModal(false);
    if (activeSession.startsWith("documentquery")) {
      await deleteDocumentID(email, deleteSession, allValues.collection);
      setDocumentReload((current) => !current);
      navigate("/documentquery");
    } else if (activeSession.startsWith("omnilens")) {
      let body = {
        email: email,
        deleteSession: deleteSession,
      };
      await omnilensClearDocSearchSessionData(body);
      setOmnilensReload((current) => !current);
      navigate("/omnilens");
    } else if (activeSession.startsWith("pubmedquery")) {
      let body = {
        email: email,
        session_id: deleteSession,
      };
      await delete_pubMed_SessionHistoryData(body);
      sessionHistoryPubMedData();
      navigate("/pubmedquery");
    } else if (activeSession.startsWith("dataDetective")) {
      await deleteDataDeleteSession(email, detectivePersonaVal, deleteSession);
      setDataDectreload((current) => !current);
      setCurretSession("");
      navigate("/dataDetective");
    } else if (activeSession.startsWith("comdataDetective")) {
      await comDetectDeleteSession(email, comPersonaVal, deleteSession);
      setComDectreload((current) => !current);
      setCurretSession("");
      navigate("/comdataDetective");
    } else if (activeSession.startsWith("zoom")) {
      await deleteZoomDeleteSession(email, zoomCollValue, deleteSession);
      setZoomReload((current) => !current);
      setCurretSession("");
      navigate("/zoom");
    } else if (activeSession.startsWith("newquery")) {
      await deleteSessionid(email, sessionPersonaVal, deleteSession);
      setReload((current) => !current);
      navigate("/newquery");
    } else if (activeSession.startsWith("textchartquery")) {
      await deleteDashSessionid(email, deleteSession, personaValueList.label);
      setDashReload((current) => !current);
      navigate("/textchartquery");
    } else if (activeSession.startsWith("dashboardquery")) {
      await deleteDashboardID(email, deleteSession, dashboardname.name);
      setDashboardReload((current) => !current);
      navigate("/dashboardquery");
    } else {
      await deleteTableauSessionId(email, deleteSession, dashboardname.name);
      setTableauReload((current) => !current);
      navigate("/tableauquery");
    }
  };

  useEffect(() => {
    const Admin = sessionStorage.getItem("admin");
    if (Admin === "true") {
      setIsAdmin(true);
    }
  }, []);

  //pubmed source filter and relevence filter onchange
  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    const updatedFilterContent = {
      ...filterContent,
      source: checked
        ? [...filterContent.source, value]
        : filterContent.source.filter((item) => item !== value),
    };
    setFilterContent(updatedFilterContent);
  };
  const handleChangeRadioBtn = (event) => {
    const value = event.target.value;

    setFilterContent({
      ...filterContent,
      relevance: filterContent.relevance === value ? "" : value,
    });
  };

  const handlePublicationType = (event) => {
    const { value, name } = event.target;
    setIsGenInspData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClearPublicationType = (name) => {
    setIsSubBtnDisable(false);
    setIsGenInspData((prevData) => ({
      ...prevData,
      [name]: "",
    }));
  };

  //handleClickNewQuery
  const handleClickNewQuery = () => {
    console.log(path, "hello");
    console.log(activeSession, "hello11");

    setNewQuery((e) => !e);
    setCurrentIndex();
    if (activeSession.startsWith("pubmedquery")) {
    setAllResponses([]);
      navigate("/pubmedquery");
    }
    if (activeSession.startsWith("newquery")) {
      navigate("/newquery");
    }
    if (activeSession.startsWith("textchartquery")) {
      navigate("/textchartquery");
    }
    if (
      activeSession.startsWith("documentquery") ||
      activeSession.startsWith("seeFiles")
    ) {
      navigate("/documentquery");
    }
    if (activeSession.startsWith("omnilens")) {
      navigate("/omnilens");
    }
    if (activeSession.startsWith("pubmedquery")) {
      navigate("/pubmedquery");
    }
    if (activeSession.startsWith("dataDetective")) {
      navigate("/dataDetective");
    }
    if (activeSession.startsWith("comdataDetective")) {
      navigate("/comdataDetective");
    }
    if (activeSession.startsWith("zoom")) {
      navigate("/zoom");
    }
    if (activeSession.startsWith("dashboardquery")) {
      dashboardname.name = "";
      dashboardname.region = "";
      setDashboardsessionslist([]);
      navigate("/dashboardquery");
    }
    if (activeSession.startsWith("tableauquery")) {
      dashboardname.name = "";
      dashboardname.region = "";
      setTableauSessionslist([]);
      navigate("/tableauquery");
    }
  };

  //handleClickManger
  const handleClickManager = () => {
    setIsOpen(false);
    setIsSideBarOpen(false);
    navigate("/admin/user");
    setCurretSession("");
  };

  const handleCloseModalFaq = () => {
    setFaqVisibleModal(false);
  };

  //handleChangeGeneIns
  const handleChangeGene = (e) => {
    let { name, value } = e.target;
    let setInputValue = "";

    if (name === "geneFileInput") {
      setIsFileDisableBtn(false);
      setFileInputKey((prevKey) => prevKey + 1);
      const file = e.target.files[0];
      setSelectedFileName(file ? file.name : "No file chosen");

      handleFileChosen(file);

      setInputValue = e.target.files[0];
      setIsTexteDisableBtn(true);
      setIsFileUpload(true);
    } else if (name === "geneTextArea") {
      let updateCase = value.toUpperCase();
      setIsFileUpload(false);
      if (value.length === 0) {
        setIsFileDisableBtn(false);
      } else {
        setIsFileDisableBtn(true);
      }
      let valueConvertUpperCase = updateCase
        .replace(/[,\s]/g, "\n")
        .toUpperCase();
      setInputValue = valueConvertUpperCase;
    } else {
      setInputValue = value;
    }
    setIsGenInspData((prevData) => ({
      ...prevData,
      [name]: setInputValue,
    }));

    setIsSubBtnDisable(false);
  };

  const handleFormValidation = () => {
    let status = true;

    if (
      isGeneInspData?.geneTextArea.length === 0 &&
      isGeneInspData.geneFileInput === ""
    ) {
      status = false;
      showToast("Please Enter Input or File");
    }
    return status;
  };

  function removeDuplicatesFromStringFile(inputString) {
    const values = inputString
      .split("\n")
      .map((item) => item.trim().replace(/\r/, ""))
      .filter((item) => item !== "");
    const uniqueValues = [...new Set(values)];
    const resultString = uniqueValues.join("\n");

    return resultString;
  }

  function removeDuplicatesFromString(inputString) {
    // Split the input values by '\n' (newline) and remove leading/trailing spaces
    const values = inputString.split("\n").map((item) => item.trim());
    // Remove duplicates using a Set
    const uniqueValues = [...new Set(values)];
    // Join the unique values back into a string
    const resultString = uniqueValues.join("\n");
    return resultString;
  }

  const handleToCheckDuplicate = (list) => {
    let status = true;

    const valueArray = list
      .split(/\r?\n/)
      .map((value) => value.trim())
      .filter((value) => value !== "");

    const valueCounts = {};
    const duplicateValues = [];
    // Count occurrences of each value
    valueArray.forEach((value) => {
      valueCounts[value] = (valueCounts[value] || 0) + 1;
      if (valueCounts[value] === 2) {
        duplicateValues.push(value);
      }
    });

    if (duplicateValues.length > 0) {
      status = false;

      console.log(duplicateValues);
      let splitDuplicates = duplicateValues
        .map((item) => `"${item}"`)
        .join(" , ");
      setIsDuplicateList(splitDuplicates);
    }
    return status;
  };

  //handleSearchSubmit
  const handleSearchSumbit = (getData) => {
    let validationStatus = handleFormValidation();

    if (!validationStatus) {
      return;
    }

    let payload;
    const { genPublicationType, geneDiesesInput, geneTextArea, genSortByType } =
      isGeneInspData;

    // Replace multiple consecutive newlines with a single newline
    let removeNextLineInput = geneTextArea.replace(/\n+/g, "\n");

    if (removeNextLineInput.startsWith("\n")) {
      removeNextLineInput = removeNextLineInput.slice(1);
    }
    if (removeNextLineInput.endsWith("\n")) {
      removeNextLineInput = removeNextLineInput.slice(0, -1);
    }

    let conditionCheck = isDuplicateModal ? getData : removeNextLineInput;
    let duplicateStatus = true;
    if (!isFileUpload) {
      duplicateStatus = handleToCheckDuplicate(conditionCheck);
    }

    if (!duplicateStatus) {
      setIsDuplicateModal(true);
      return;
    }
    handleCheckLength(conditionCheck);
    setIsSubBtnDisable(true);
    startLoading(true);

    let geneSessionId = getRandomNumberFromUuid();
    setIsSubmitGeneIns(false);

    sessionStorage.setItem("geneSessionId", geneSessionId);

    if (isFileUpload) {
      payload = {
        encoded_content: content.content,
        date_filters: genPublicationType,
        disease_name: geneDiesesInput ? geneDiesesInput : "",
        sort_by_str: genSortByType === "best_match" ? "best_match" : "",
        session_id: geneSessionId,
      };
    } else {
      payload = {
        genes_text: conditionCheck,
        date_filters: genPublicationType,
        disease_name: geneDiesesInput ? geneDiesesInput : "",
        sort_by_str: genSortByType === "best_match" ? "best_match" : "",
        session_id: geneSessionId,
      };
    }

    let result = isFileUpload ? uploadDocGen(payload) : fetchGeneData(payload);

    setTimeout(() => {
      setIsSubmitGeneIns(true);
    }, 30000);
  };

  const handleCheckLength = (val) => {
    const lengthApiCall = val.split(/\s+/).filter((word) => word !== "").length;
    if (lengthApiCall) {
      setIsApiCallLength(lengthApiCall);
    }
  };

  const handleFileClear = () => {
    setSelectedFileName("No file chosen");
    setIsSubBtnDisable(false);
    setGeneTableClear((e) => !e);
    setIsTexteDisableBtn(false);
    setIsFileDisableBtn(false);
    setFileInputKey((prevKey) => prevKey + 1);
    fileInputRef.current.value = "";
    setIsGenInspData((prevData) => ({
      ...prevData,
      geneFileInput: "",
    }));
  };

  const handleTextAreaClear = () => {
    setIsSubBtnDisable(false);
    setGeneTableClear((e) => !e);
    setIsFileDisableBtn(false);
    setIsGenInspData((prevData) => ({
      ...prevData,
      geneTextArea: "",
    }));
  };

  const handleFileRead = (e) => {
    const content = fileReader.result;

    let a;
    if (content.split(",")[0].includes("presentationml.presentation")) {
      a = {
        format: "pptx",
        content: content.split(",")[1],
      };
    }
    if (content.split(",")[0].includes("pdf")) {
      a = {
        format: "pdf",
        content: content.split(",")[1],
      };
    }
    if (content.split(",")[0].includes("wordprocessingml.document")) {
      a = {
        format: "docx",
        content: content.split(",")[1],
      };
    }
    if (content.split(",")[0].includes("plain")) {
      // Assuming plain text files based on the absence of specific formats
      a = {
        format: "txt",
        content: content.split(",")[1],
      };
    }
    // let convertDecode = base64Decode(a.content);
    const decodedString = atob(a.content);
    let uniqueData = removeDuplicatesFromStringFile(decodedString);
    handleCheckLength(uniqueData);
    const encodedString = btoa(uniqueData);
    // handleCheckLength(uniqueData);
    a = {
      format: "txt",
      content: encodedString,
    };
    setContent(a);

    let checkDuplicate = handleToCheckDuplicate(decodedString);
    if (!checkDuplicate) {
      setIsDuplicateModal(true);
      return;
    }
  };

  const handleFileChosen = (file) => {
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsDataURL(file);
  };

  const renderClearSessionButton = (list, getPath, onClick) => {
    if (
      // !activePubmedUrl &&
      !activeAdminUrl &&
      list &&
      list.length > 0 &&
      currentPath.startsWith(getPath)
    ) {
      return (
        <div
          className="d-flex justify-content-end iconHover clearHistoryTitle mt-3"
          onClick={onClick}
        >
          {commonStr.clearSession}
        </div>
      );
    } else {
      return <div />;
    }
  };

  const handleToDuplicateModal = () => {
    let uniqueData = removeDuplicatesFromString(isGeneInspData?.geneTextArea);
    setIsGenInspData((prevData) => ({
      ...prevData,
      geneTextArea: uniqueData,
    }));

    handleSearchSumbit(uniqueData);
    setIsDuplicateModal(false);
    setIsDuplicateList([]);
  };

  //handleToNavigateId
  const handleToNavigateId = (data, path) => {
    setCurrentIndex();
    setCurretSession(data);
    setEditSession(false);
    // console.log("dagta", data);
    // console.log("path", path);
    navigate(`/${path}/${data}`);
  };

  const handleToCloseClick = () => {
    setEditSession(false);
    setCurrentIndex();
  };

  const handleClickIcon = () => {
    setIsOpen(!isOpen);
    setIsSideBarOpen(!isOpen);
  };

  const handleChangePersona = (event) => {
    setPersonValuePubMed(event.target.value);
  };

  console.log(currentSession, "currentSession");

  return (
    <div
      className={`sidebar ${isOpen ? "sidebarMax" : "sidebarMin"} ${isTablet ? "tabletSidebar" : ""}`}
    >
      {!isOpen && isTablet ? (
        <TfiMenuAlt size={30} className="menuIcon" onClick={handleClickIcon} />
      ) : (
        <>
          <div className="displayFlexCenter">
            <TfiArrowCircleRight
              size={20}
              className={`mt-3 iconHover ${isOpen ? "d-none" : "d-block"}`}
              onClick={() => handleClickIcon()}
            />
          </div>
          <div
            className={`sidebar1 iconHover ${isOpen ? "d-block" : "d-none"}`}
          >
            <div className="d-flex align-items-center justify-content-between">
              <p className="genInTitle">{commonStr.genIn}</p>
              {isTablet ? (
                <IoMdCloseCircleOutline size={24} onClick={handleClickIcon} />
              ) : (
                <TfiArrowCircleLeft size={17} onClick={handleClickIcon} />
              )}
            </div>

            <div
              className={`${isOpen ? "d-block" : "d-none"} ${
                (activeContentUrl ||
                  activeContentGenUrl ||
                  activefileUploadingUrl ||
                  activeSentimentAnalysisUrl ||
                  activeMedLingoUrlSenti ||
                  activeMedPulseUrlSenti ||
                  activeMedInsightsUrlSenti ||
                  activeMedLingoUrl ||
                  activeMedPulseUrl ||
                  activeMedInsightsUrl ||
                  activeMlrUrl ||
                  activePersonalIdentityUrl ||
                  activeWaterMarkUrl ||
                  activePersonalIdentityImageUrl) &&
                "contentManagerAlign"
              } `}
            >
              {!activeGeneInsUrl && (
                <SidebarCurrentSession
                  handleClickNew={handleClickNewQuery}
                  activeContentGenUrl={activeContentGenUrl}
                  activeMlrUrl={activeMlrUrl}
                  activePersonalIdentityUrl={activePersonalIdentityUrl}
                  activeWaterMarkUrl={activeWaterMarkUrl}
                  activePersonalIdentityImageUrl={
                    activePersonalIdentityImageUrl
                  }
                  activefileUploadingUrl={activefileUploadingUrl}
                  activeSentimentAnalysisUrl={activeSentimentAnalysisUrl}
                  activeMedLingoUrlSenti={activeMedLingoUrlSenti}
                  activeMedPulseUrlSenti={activeMedPulseUrlSenti}
                  activeMedInsightsUrlSenti={activeMedInsightsUrlSenti}
                  activeMedLingoUrl={activeMedLingoUrl}
                  activeMedPulseUrl={activeMedPulseUrl}
                  activeMedInsightsUrl={activeMedInsightsUrl}
                  activeContentUrl={activeContentUrl}
                  activeOmnilensUrl={activeOmnilensUrl}
                  currentSession={currentSession}
                  editSession={editSession}
                  activeEmergencySolution={activeEmergencySolution}
                  activeCallInsights_2={activeCallInsights_2}
                  activePromptExtractFilesPage={activePromptExtractFilesPage}
                  handleEditCurrentSession={(e) => {
                    setCurretSession(e.target.value);
                    handleInputChange(e);
                  }}
                  handleSessionNameChange={handleSessionNameChange}
                  handleSaveClick={handleSaveClick}
                  handleCloseClick={() => {
                    setEditSession(false);
                    setCurrentIndex();
                  }}
                  activeDocumentGeneration={activeDocumentGeneration}
                  activeDocumentStructure={activeDocumentStructure}
                  activeDocumentHeading={activeDocumentHeading}
                  activeDocumentEditor={activeDocumentEditor}
                  activeDocumentFileExtract={activeDocumentFileExtract}
                  activePubmedUrl={activePubmedUrl}
                />
              )}

              {activeNewQryUrl && (
                <SidebarSessionHistory
                  firstList={list}
                  dataList={sessionslist}
                  currentIndex={currentIndex}
                  handleInputChange={handleInputChange}
                  handleToNavigateId={(e) => handleToNavigateId(e, "newquery")}
                  handleToSaveHandler={handleSaveClick}
                  handleToCloseHandler={handleToCloseClick}
                  handleSetCurrentInx={(i) => setCurrentIndex(i)}
                  handleToDeleteSession={(e) => handleDeleteSession(e)}
                  handleToDownloadSession={(e) => handleToDownloadSession(e)}
                />
              )}

              {(activeDocumentUrl || activeSeeFilesUrl) && (
                <SidebarSessionHistory
                  firstList={documentlist}
                  dataList={documentsessionslist}
                  currentIndex={currentIndex}
                  handleInputChange={handleInputChange}
                  handleToNavigateId={(e) =>
                    handleToNavigateId(e, "documentquery")
                  }
                  handleToSaveHandler={handleSaveClick}
                  handleToCloseHandler={handleToCloseClick}
                  handleSetCurrentInx={(i) => setCurrentIndex(i)}
                  handleToDeleteSession={(e) => handleDeleteSession(e)}
                />
              )}

              {activeOmnilensUrl && (
                <SidebarSessionHistory
                  firstList={omnilenslist}
                  dataList={omnilenssessionslist}
                  currentIndex={currentIndex}
                  handleInputChange={handleInputChange}
                  handleToNavigateId={(e) => handleToNavigateId(e, "omnilens")}
                  handleToSaveHandler={handleSaveClick}
                  handleToCloseHandler={handleToCloseClick}
                  handleSetCurrentInx={(i) => setCurrentIndex(i)}
                  handleToDeleteSession={(e) => handleDeleteSession(e)}
                />
              )}

              {activePubmedUrl && (
                <>
                  <div>
                    <FormControl
                      sx={{
                        m: 2,
                        minWidth: 200,
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "white",
                          },
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white",
                          },
                        },
                      }}
                    >
                      <Select
                        value={personValuePubMed}
                        onChange={handleChangePersona}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        style={{ color: "white" }}
                      >
                        <MenuItem value={"No_Filter"}>No_Filter</MenuItem>
                        <MenuItem value={"Proband_GL_var"}>
                          Proband_GL_var
                        </MenuItem>
                        <MenuItem value={"CC_Studies"}>CC_Studies</MenuItem>
                        <MenuItem value={"Pathogenetics"}>
                          Pathogenetics
                        </MenuItem>
                      </Select>
                      <FormHelperText style={{ color: "white" }}>
                        Persona
                      </FormHelperText>
                    </FormControl>
                  </div>

                  <div>
                    <div className="current-session__border mt-4 px-2 py-3">
                      <div className="current-session__border__titlewrap">
                        <img src={messageIcon} alt="sidebar" />
                        <p className="current-session__border__titlewrap__title">
                          {commonStr.currentSession}
                        </p>
                      </div>
                      {currentSession !== "" || editSession ? (
                        <div className="current-session__active mt-3">
                          <img src={messageIcon1} alt="sidebar" />
                          &ensp;
                          <div className="current-session__inputwrap">
                            {editSession ? (
                              <input
                                type="text"
                                value={currentSession}
                                onChange={(e) => {
                                  setCurretSession(e.target.value);
                                  handleInputChange(e);
                                }}
                                className="current-session__inputwrap__input"
                              />
                            ) : (
                              <div
                                className="current-session__inputwrap__inputvalue"
                                onClick={handleSessionNameChange}
                              >
                                {currentSession}
                              </div>
                            )}
                            <div className="current-session__iconwrap">
                              {editSession ? (
                                <>
                                  <TiTick
                                    size={25}
                                    className="current-session__iconwrap__iconwhite"
                                    onClick={handleSaveClick}
                                  />

                                  <IoIosClose
                                    size={25}
                                    className="current-session__iconwrap__iconwhite"
                                    onClick={() => {
                                      setEditSession(false);
                                      setCurrentIndex();
                                    }}
                                  />
                                </>
                              ) : (
                                <BiEdit
                                  size={20}
                                  className="current-session__iconwrap__iconwhite ml-1"
                                  onClick={handleSessionNameChange}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <SidebarSessionHistory
                    firstList={pubMedlist}
                    dataList={pubMedsessionslist}
                    currentIndex={currentIndex}
                    handleInputChange={handleInputChange}
                    handleToNavigateId={(e) =>
                      handleToNavigateId(e, "pubmedquery")
                    }
                    handleToSaveHandler={handleSaveClick}
                    handleToCloseHandler={handleToCloseClick}
                    handleSetCurrentInx={(i) => setCurrentIndex(i)}
                    handleToDeleteSession={(e) => handleDeleteSession(e)}
                  />
                  {renderClearSessionButton(
                    pubMedsessionslist,
                    "/pubmedquery",
                    handleDeleteHistory
                  )}
                </>
              )}

              {activeZoomUrl && (
                <SidebarSessionHistory
                  firstList={zoomList}
                  dataList={zoomSessionslist}
                  currentIndex={currentIndex}
                  handleInputChange={handleInputChange}
                  handleToNavigateId={(e) => handleToNavigateId(e, "zoom")}
                  handleToSaveHandler={handleSaveClick}
                  handleToCloseHandler={handleToCloseClick}
                  handleSetCurrentInx={(i) => setCurrentIndex(i)}
                  handleToDeleteSession={(e) => handleDeleteSession(e)}
                />
              )}

              {activeDataDetectiveUrl && (
                <SidebarSessionHistory
                  firstList={dataDetectiveList}
                  dataList={dataDecSessionList}
                  currentIndex={currentIndex}
                  handleInputChange={handleInputChange}
                  handleToNavigateId={(e) =>
                    handleToNavigateId(e, "dataDetective")
                  }
                  handleToSaveHandler={handleSaveClick}
                  handleToCloseHandler={handleToCloseClick}
                  handleSetCurrentInx={(i) => setCurrentIndex(i)}
                  handleToDeleteSession={(e) => handleDeleteSession(e)}
                />
              )}

              {activeComDetectiveUrl && (
                <SidebarSessionHistory
                  firstList={comDetectiveList}
                  dataList={comDecSessionList}
                  currentIndex={currentIndex}
                  handleInputChange={handleInputChange}
                  handleToNavigateId={(e) =>
                    handleToNavigateId(e, "comdataDetective")
                  }
                  handleToSaveHandler={handleSaveClick}
                  handleToCloseHandler={handleToCloseClick}
                  handleSetCurrentInx={(i) => setCurrentIndex(i)}
                  handleToDeleteSession={(e) => handleDeleteSession(e)}
                />
              )}

              {activeTxtChartUrl && (
                <SidebarSessionHistory
                  firstList={dashList}
                  dataList={dashSessionList}
                  currentIndex={currentIndex}
                  handleInputChange={handleInputChange}
                  handleToNavigateId={(e) =>
                    handleToNavigateId(e, "textchartquery")
                  }
                  handleToSaveHandler={handleSaveClick}
                  handleToCloseHandler={handleToCloseClick}
                  handleSetCurrentInx={(i) => setCurrentIndex(i)}
                  handleToDeleteSession={(e) => handleDeleteSession(e)}
                />
              )}
              {activeTableauUrl && (
                <SidebarSessionHistory
                  firstList={isLoaderSession}
                  dataList={tableauSessionslist}
                  currentIndex={currentIndex}
                  handleInputChange={handleInputChange}
                  handleToNavigateId={(e) =>
                    handleToNavigateId(e["session name"], "tableauquery")
                  }
                  handleToSaveHandler={handleSaveClick}
                  handleToCloseHandler={handleToCloseClick}
                  handleSetCurrentInx={(i, e) => {
                    setCurrentIndex(i);
                    setOldValue(e["session name"]);
                  }}
                  handleToDeleteSession={(e) =>
                    handleDeleteSession(e["session name"])
                  }
                  activeSession
                />
              )}
              {activeDashboardUrl && (
                <SidebarSessionHistory
                  firstList={dashboardlist}
                  dataList={dashboardsessionslist}
                  currentIndex={currentIndex}
                  handleInputChange={handleInputChange}
                  handleToNavigateId={(e) =>
                    handleToNavigateId(e["session name"], "dashboardquery")
                  }
                  handleToSaveHandler={handleSaveClick}
                  handleToCloseHandler={handleToCloseClick}
                  handleSetCurrentInx={(i, e) => {
                    setCurrentIndex(i);
                    setOldValue(e["session name"]);
                  }}
                  handleToDeleteSession={(e) =>
                    handleDeleteSession(e["session name"])
                  }
                  activeSession
                />
              )}
              {/* ? pubmed page */}
              {activePubmedUrl && (
                <>
                  <div className="filterOptions">
                    <SidBarPubmedFilter
                      onClickClear={() =>
                        setFilterContent({ ...filterContent, source: [] })
                      }
                      showListData={sourchFilterData}
                      filterContent={filterContent}
                      inputOnChange={handleCheckboxChange}
                    />
                    <SidBarPubmedFilter
                      onClickClear={() =>
                        setFilterContent({ ...filterContent, relevance: "" })
                      }
                      showListData={relevanceData}
                      filterContent={filterContent}
                      inputOnChange={handleChangeRadioBtn}
                      lableTitle={commonStr.relevance}
                      type="radio"
                    />
                  </div>
                </>
              )}

              {activeGeneInUrl && (
                <div className="filterOptions filterAlignUnset mt-2">
                  <div className="padding10 w-100">
                    <h6>{commonStr.uploadGeneList}</h6>
                    <p>{commonStr.step1GeneList}</p>
                    <div className="geneTextInputWrap">
                      <CommonTextArea
                        id="geneInspectorText"
                        rows="4"
                        cols="50"
                        value={isGeneInspData?.geneTextArea}
                        onChange={handleChangeGene}
                        name="geneTextArea"
                        disabled={isTextDisableBtn}
                        placeholder=""
                      />
                      <CommonClearBtn
                        isVisible={isGeneInspData.geneTextArea}
                        onClick={handleTextAreaClear}
                        customClass="margin10px"
                      />
                    </div>

                    <p>{commonStr.or}</p>
                    <p>{commonStr.uploadFile}</p>

                    <div className="geneFileUploadWrap">
                      <div className="genefileUploadbtn">
                        <div className="genefileBtn">
                          <input
                            name="geneFileInput"
                            type="file"
                            id="actual-btn"
                            hidden
                            onChange={handleChangeGene}
                            key={fileInputKey}
                            ref={fileInputRef}
                            disabled={isFileDisableBtn}
                          />
                          <label htmlFor="actual-btn">Choose File</label>
                          <span id="file-chosen">{selectedFileName}</span>
                        </div>
                        <CommonClearBtn
                          isVisible={isGeneInspData.geneFileInput.name}
                          onClick={handleFileClear}
                        />{" "}
                      </div>
                    </div>

                    <div className="mt-3">
                      <p>{commonStr.step2Disease}</p>
                      <p>{commonStr.optional}</p>
                      <CommonInput
                        name="geneDiesesInput"
                        onChange={handleChangeGene}
                        value={isGeneInspData?.geneDiesesInput}
                        className="geneDisesInput"
                      />
                    </div>
                  </div>
                  <SidBarPubmedFilter
                    onClickClear={() =>
                      handleClearPublicationType("genPublicationType")
                    }
                    showListData={relevanceData}
                    filterContent={isGeneInspData}
                    inputOnChange={handlePublicationType}
                    lableTitle={commonStr.relavanceFilter}
                    type="radio"
                    customClass="marginTop10px"
                    isGenePage
                    name="genPublicationType"
                  />
                  <SidBarPubmedFilter
                    onClickClear={() =>
                      handleClearPublicationType("genSortByType")
                    }
                    showListData={soryByData}
                    filterContent={isGeneInspData}
                    inputOnChange={handlePublicationType}
                    lableTitle={commonStr.sortBy}
                    type="radio"
                    customClass="marginTop10px"
                    isGenePage
                    name="genSortByType"
                  />
                  <CommonBtn
                    title={commonStr.search}
                    className={`outlineBtn mr-2 mt-3 iconHover searchGenBtn ${
                      isSubBtnDisable && "disableBtn"
                    }`}
                    onClick={handleSearchSumbit}
                    disabled={isSubBtnDisable}
                  />
                </div>
              )}

              {activeAdminUrl && <div className="heightDummy" />}
              {renderClearSessionButton(
                sessionslist,
                "/newquery",
                handleDeleteHistory
              )}
              {renderClearSessionButton(
                dashSessionList,
                "/textchartquery",
                handleDeleteHistory
              )}
              {renderClearSessionButton(
                documentsessionslist,
                "/seeFiles",
                handleDeleteHistory
              )}
              {renderClearSessionButton(
                documentsessionslist,
                "/documentquery",
                handleDeleteHistory
              )}
              {renderClearSessionButton(
                omnilenssessionslist,
                "/omnilens",
                handleDeleteHistory
              )}
              {renderClearSessionButton(
                dataDecSessionList,
                "/dataDetective",
                handleDeleteHistory
              )}
              {renderClearSessionButton(
                comDecSessionList,
                "/comdataDetective",
                handleDeleteHistory
              )}
              {renderClearSessionButton(
                tableauSessionslist,
                "/tableauquery",
                handleDeleteHistory
              )}
              {renderClearSessionButton(
                dashboardsessionslist,
                "/dashboardquery",
                handleDeleteHistory
              )}

              {renderClearSessionButton(
                zoomSessionslist,
                "/zoom",
                handleDeleteHistory
              )}

              {activeEmergencySolution && (
                <Faq
                  showModal={faqVisibleModal}
                  onCancel={() => handleCloseModalFaq()}
                />
              )}

              {isAdmin && !activeOmnilensUrl && (
                <SidebarManagerItem onClick={handleClickManager} />
              )}
            </div>
          </div>

          <SideBarLogout
            isOpen={isOpen}
            path={path}
            commonStr={commonStr}
            navigate={navigate}
            setLogoutModal={() => setLogoutModal(true)}
          />

          {logoutModal && (
            <CommonModal
              showModal={logoutModal}
              title={logoutTitle}
              content={`${areYouWant} ${logoutTitle} ${ques}`}
              onCancel={() => setLogoutModal(false)}
              onConfirm={handleLogout}
            />
          )}

          {isDuplicateModal && (
            <CommonModal
              showModal={isDuplicateModal}
              title={"Duplicate Remove"}
              content={`${areYouWant} remove this duplicate items`}
              listitems={isDuplicateList}
              onCancel={() => {
                setIsDuplicateModal(false);
              }}
              onConfirm={handleToDuplicateModal}
            />
          )}

          {deleteModal && (
            <CommonModal
              showModal={deleteModal}
              title={
                isDeleteSessionAll
                  ? `${clearAllHistory}`
                  : `${deleteSessionTitle}`
              }
              content={
                isDeleteSessionAll
                  ? `${areYouWant} ${clearAllHistory} ${ques}`
                  : `${areYouWant} ${deleteSessionTitle} ${deleteSession} ${ques}`
              }
              onCancel={closeDeleteModal}
              onConfirm={isDeleteSessionAll ? deleteAllHistory : finalDelete}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SidebarComponent;

import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify"; 
import { useLocation, useNavigate } from "react-router-dom";
import { FaFileImage } from "react-icons/fa";
import { documentStructureArticlefields } from "../../api/Postaction";
import { documentStructureArticleFileUpload } from "../../api/Postaction";
import { documentStructureDynamicBannerFileUpload } from "../../api/Postaction";
import { documentStructureDynamicBannerfields } from "../../api/Postaction";
import { documentStructureRepTriggerEmailFileUpload } from "../../api/Postaction";
import { documentStructureRepTriggerEmailfields } from "../../api/Postaction";
import { documentStructureFileUpload } from "../../api/Postaction";
import { documentProtocolDocFileUpload } from "../../api/Postaction";
import { documentGRDDocFileUpload } from "../../api/Postaction";
import { documentGRDTableName } from "../../api/GetActions";
import {
  documentArticleBack,
  documentDynamicBannerBack,
  documentRepTriggerEmailBack,
  documentJournalPaperBack,
} from "../../api/GetActions";
import { MyContext } from "../../context/ContextApi";
import { AnimationDocument, session } from "../../utils/images/index.js";
import { AnimationDynamicBanner } from "../../utils/images/index.js";
import { PreloaderDocument } from "../../utils/images/index.js";
import { AnimationFileUpload } from "../../utils/images/index.js";
import { GrDocumentUpload } from "react-icons/gr";
import { MdContentCopy } from "react-icons/md";
import {
  Template0,
  Template1,
  Template2,
  HighlightTemplate0,
  HighlightTemplate1,
  HighlightTemplate2,
} from "../../utils/images/index.js";
import { jsPDF } from "jspdf";
import Mammoth from "mammoth";
import "./documentStructure.css";

const DocumentStructure = () => {
  const {
    setStructureArticle,
    setConvertedMarkdown,
    setStructureHeading,
    setContentType,
    setselectedtemplate,
    setRetrivedInfo,
    setExtractedFields,
    setContentIDFileExtract,
    method,
    setMethod,
    structureArticle,
    contenttype,
    structureHeading,
    setJournalPaperTitle,
    contentID,
    setContentID,
    selectedOption,
    setSelectedOption,
  } = useContext(MyContext);
  const [tableOptions, setTableOptions] = useState([]);
  const query = new URLSearchParams(useLocation().search);
  const contentType = query.get("content");
  setContentType(contentType);

  useEffect(() => {
    const fetchTableNames = async () => {
      if (contentType === "GRD") {
        try {
          const res = await documentGRDTableName();
          if (res) {
            setTableOptions(res);
          }
        } catch (error) {
          console.error("Error fetching table names:", error);
          toast.error("Failed to fetch table names.");
        }
      }
    };

    fetchTableNames();
  }, [contentType]);

  useEffect(() => {
    if (!contenttype) return;

    const fetchBackData = async () => {
      try {
        let response;
        const payload = structureHeading?.content_id || contentID;

        switch (contenttype) {
          case "Article":
            response = await documentArticleBack(structureArticle?.content_id);
            if (response) {
              setTitle(response.title || "");
              setTone(response.tone || "");
              setKeyTopics(response.key_topics || []);
              setMethod(response.methods || "");

              if (
                response.methods === "Q&A" &&
                Array.isArray(response.uploaded_files)
              ) {
                setUploadedFiles(
                  response.uploaded_files.map((file) => ({
                    name: file.file_name,
                    size: file.file_size,
                    status: "Uploaded",
                  }))
                );
              }
            }
            break;

          case "Dynamic Banner":
            response = await documentDynamicBannerBack(payload);
            if (response) {
              setProduct(response.product || "");
              setAudience(response.audience || "");
              setKeymessages(response.key_messages || []);
              setCalltoaction(response.call_to_actions || "");
              setPrimarykeywords(response.primary_keywords || "");
              setClaims(response.claims || []);
              setFrames(response.frames || []);
              setInstructions(response.instructions || "");
              setMethodDB(response.methods || "");
            }
            break;

          case "Email":
            response = await documentRepTriggerEmailBack(payload);
            if (response) {
              setProduct(response.product || "");
              setIndication(response.indication || "");
              setKeymessages(response.key_messages || []);
              setCalltoaction(response.call_to_actions || "");
              setPrimarykeywords(response.primary_keywords || []);
              setTherapeuticArea(response.therapeutic_area || "");
              setMethodDB(response.methods || "");
            }
            break;

          case "Journal Paper":
            response = await documentJournalPaperBack(
              structureArticle?.content_id
            );
            if (response) {
              // Uncomment and use if needed
              // setJournalTitle(response.journal_title || "");
              // setAbstract(response.abstract || "");
              // setKeywords(response.keywords || []);
              // setAuthors(response.authors || []);
              // setReferences(response.references || []);
              // setMethodJournal(response.methods || "");
            }
            break;

          default:
            console.warn("Unknown content type:", contenttype);
            return;
        }
      } catch (error) {
        console.error("Error fetching back data:", error);
      }
    };

    fetchBackData();
  }, [contenttype, structureHeading, structureArticle, contentID]);

  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [keyTopics, setKeyTopics] = useState("");
  const [tone, setTone] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [product, setProduct] = useState("");
  const [audience, setAudience] = useState("");
  const [keymessages, setKeymessages] = useState("");
  const [calltoaction, setCalltoaction] = useState("");
  const [primarykeywords, setPrimarykeywords] = useState("");
  const [claims, setClaims] = useState("");
  const [frames, setFrames] = useState("");
  const [instructions, setInstructions] = useState("");
  const [indication, setIndication] = useState("");
  const [therapeuticArea, setTherapeuticArea] = useState("");
  const [methodDB, setMethodDB] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("Default");
  const [uploadedImages, setUploadedImages] = useState({
    Template1: null,
    Template2: null,
  });
  const [uploadedTemplate, setUploadedTemplate] = useState([]);
  const [uploadedSource, setUploadedSource] = useState([]);
  const [activeTab, setActiveTab] = useState("Template");
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const allowedExtensions = ["pdf", "doc", "docx", "ppt", "pptx"];

    const validFiles = files.filter((file) =>
      allowedExtensions.includes(file.name.split(".").pop().toLowerCase())
    );

    if (validFiles.length < files.length) {
      alert(
        "Some files were not accepted because they are not PDF or DOC files..."
      );
    }

    const newFiles = validFiles.map((file) => ({
      name: file.name,
      size: (file.size / (1024 * 1024)).toFixed(2) + " MB",
      status: "Upload Successful",
      file: file,
    }));

    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleTemplateClick = (template) => {
    setSelectedTemplate(template);
    setselectedtemplate(template);
  };

  const handleImageUpload = (template, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setUploadedImages((prevImages) => ({
          ...prevImages,
          [template]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = (template) => {
    setUploadedImages((prevImages) => ({
      ...prevImages,
      [template]: null,
    }));
  };

  // const handleFileUpload = async (e, type) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const fileData = {
  //       name: file.name,
  //       file,
  //       fileUrl: URL.createObjectURL(file),
  //     };

  //     if (file.name.endsWith(".docx")) {
  //       try {
  //         const arrayBuffer = await file.arrayBuffer();
  //         const result = await Mammoth.extractRawText({ arrayBuffer });

  //         const pdf = new jsPDF();
  //         const pageHeight = pdf.internal.pageSize.height - 20;
  //         let y = 10;

  //         const splitText = pdf.splitTextToSize(result.value, 180);

  //         splitText.forEach((line) => {
  //           if (y > pageHeight) {
  //             pdf.addPage();
  //             y = 10;
  //           }
  //           pdf.text(line, 10, y);
  //           y += 10;
  //         });

  //         const pdfBlob = pdf.output("blob");
  //         fileData.fileUrl = URL.createObjectURL(pdfBlob);
  //       } catch (error) {
  //         console.error("Failed to process .docx file:", error);
  //         alert("Unable to preview .docx file.");
  //         return;
  //       }
  //     }

  //     type === "Template"
  //       ? setUploadedTemplate(fileData)
  //       : setUploadedSource(fileData);
  //   }
  // };

  // const handleDeleteFile = (type) => {
  //   type === "Template" ? setUploadedTemplate(null) : setUploadedSource(null);
  // };

  // const handleCopy = (content) => {
  //   navigator.clipboard
  //     .writeText(content)
  //     .then(() => {
  //       toast.success("Filename Copied to clipboard!");
  //     })
  //     .catch((err) => {
  //       console.error("Failed to copy Filename : ", err);
  //       toast.error("Failed to copy Filename . Please try again.");
  //     });
  // };

  // const handleSubmitDocument = async () => {
  //   setLoading(true);
  //   try {
  //     if (!uploadedTemplate || !uploadedSource) {
  //       toast.error("Both Template and Source files must be uploaded.");
  //     }

  //     const formData = new FormData();
  //     formData.append("upload_template", uploadedTemplate.file);
  //     formData.append("upload_source_file", uploadedSource.file);

  //     const userId = sessionStorage.getItem("userID");
  //     if (!userId) {
  //       throw new Error("User ID not found in session storage.");
  //     }

  // const response = await documentStructureFileUpload(formData, userId);
  // console.log(response.title, "response.title");
  // setConvertedMarkdown(response.markdown_file_path);
  // setExtractedFields(response.extracted_text);
  // setContentIDFileExtract(response.content_id);
  // setJournalPaperTitle(response.title);

  // if (response && response.retrived_info) {
  //   setRetrivedInfo(response.retrived_info);
  //   navigate("/documentFileExtract");
  //     } else {
  //       console.error("Retrieved info not found in response.");
  //       toast.error("Unexpected error: Retrieved info not found.");
  //     }
  //   } catch (error) {
  //     console.error("Error in handleSubmitDocument:", error);
  //     toast.error("An error occurred during the upload process.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleFileUpload = async (e, type) => {
    const files = Array.from(e.target.files); // Convert FileList to Array
    if (files.length === 0) return;

    const uploadedFiles = await Promise.all(
      files.map(async (file) => {
        const fileData = {
          name: file.name,
          file,
          fileUrl: URL.createObjectURL(file),
        };

        if (file.name.endsWith(".docx")) {
          try {
            const arrayBuffer = await file.arrayBuffer();
            const result = await Mammoth.extractRawText({ arrayBuffer });

            const pdf = new jsPDF();
            const pageHeight = pdf.internal.pageSize.height - 20;
            let y = 10;
            const splitText = pdf.splitTextToSize(result.value, 180);

            splitText.forEach((line) => {
              if (y > pageHeight) {
                pdf.addPage();
                y = 10;
              }
              pdf.text(line, 10, y);
              y += 10;
            });

            const pdfBlob = pdf.output("blob");
            fileData.fileUrl = URL.createObjectURL(pdfBlob);
          } catch (error) {
            console.error("Failed to process .docx file:", error);
            alert("Unable to preview .docx file.");
            return null;
          }
        }

        return fileData;
      })
    );

    const validFiles = uploadedFiles.filter((file) => file !== null);

    if (type === "Template") {
      setUploadedTemplate((prev) => [...prev, ...validFiles]);
    } else {
      setUploadedSource((prev) => [...prev, ...validFiles]);
    }
  };

  const handleCopy = (files) => {
    const filenames = files.map((file) => file.name).join(", ");
    navigator.clipboard
      .writeText(filenames)
      .then(() => {
        toast.success("Filenames copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy filenames: ", err);
        toast.error("Failed to copy filenames. Please try again.");
      });
  };

  const handleDeleteFile = (type, fileName) => {
    if (type === "Template") {
      setUploadedTemplate((prev) =>
        prev.filter((file) => file.name !== fileName)
      );
    } else {
      setUploadedSource((prev) =>
        prev.filter((file) => file.name !== fileName)
      );
    }
  };

  const handleSubmitDocument = async () => {
    setLoading(true);
    try {
      if (!selectedOption || selectedOption === "Select Table") {
        toast.error("Please select a table before proceeding.");
        setLoading(false);
        return;
      }

      if (uploadedTemplate.length === 0 || uploadedSource.length === 0) {
        toast.error("Both Template and Source files must be uploaded.");
        setLoading(false);
        return;
      }

      const formData = new FormData();

      uploadedTemplate.forEach((file) => {
        formData.append("upload_template", file.file);
      });

      uploadedSource.forEach((file) => {
        formData.append("upload_source_file", file.file);
      });

      const userId = sessionStorage.getItem("userID");
      if (!userId) {
        throw new Error("User ID not found in session storage.");
      }

      let response = null;

      if (contentType === "Journal Paper") {
        response = await documentStructureFileUpload(formData, userId);
      } else if (contentType === "Protocol Document") {
        response = await documentProtocolDocFileUpload(formData, userId);
      } else if (contentType === "GRD") {
        response = await documentGRDDocFileUpload(
          formData,
          userId,
          selectedOption
        );
      }

      if (!response) {
        throw new Error("No response received from the server.");
      }

      setConvertedMarkdown(response.markdown_file_path);
      setExtractedFields(response.extracted_text);
      setContentIDFileExtract(response.content_id);
      setJournalPaperTitle(response.title);
      setRetrivedInfo(response.retrived_info);

      if (response) {
        navigate("/documentFileExtract");
      } else {
        console.error("Retrieved info not found in response.");
        toast.error("Unexpected error: Retrieved info not found.");
      }
    } catch (error) {
      console.error("Error in handleSubmitDocument:", error);
      toast.error(
        error.message || "An error occurred during the upload process."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitRepTriggerEmail = async () => {
    setLoading(true);
    const userId = sessionStorage.getItem("userID");

    if (!userId) {
      toast.error("User ID not found in session storage.");
      setLoading(false);
      return;
    }

    if (
      !product ||
      !indication ||
      !keymessages ||
      !calltoaction ||
      !primarykeywords ||
      !therapeuticArea ||
      !methodDB
    ) {
      toast.error("All fields are required.");
      setLoading(false);
      return;
    }

    const payload = {
      product: product,
      indication: indication,
      key_messages: keymessages,
      call_to_actions: calltoaction,
      primary_keywords: primarykeywords,
      therapeutic_area: therapeuticArea,
      methods: methodDB,
    };

    try {
      if (methodDB === "Select File" && uploadedFiles.length > 0) {
        const formData = new FormData();
        uploadedFiles.forEach((fileObj) => {
          formData.append("files", fileObj.file);
        });

        const fileUploadResponse =
          await documentStructureRepTriggerEmailFileUpload(formData, userId);

        if (!fileUploadResponse || !fileUploadResponse.collection_name) {
          throw new Error(
            "Failed to upload files or retrieve collection_name."
          );
        }

        payload.collection_name = fileUploadResponse.collection_name;
      }

      const response = await documentStructureRepTriggerEmailfields(
        payload,
        userId
      );

      if (!response) {
        throw new Error("Failed to upload RepTriggerEmail fields.");
      }

      setStructureHeading(response);

      navigate("/documentEditor");
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.data) {
        console.error("API Error Response:", error.response.data);
      }
      setLoading(false);
    }
  };

  const handleSubmitDynamicBanner = async () => {
    setLoading(true);
    const userId = sessionStorage.getItem("userID");

    if (!userId) {
      toast.error("User ID not found in session storage.");
      setLoading(false);
      return;
    }

    if (
      !product ||
      !audience ||
      !keymessages ||
      !calltoaction ||
      !primarykeywords ||
      !claims ||
      !frames ||
      !instructions ||
      !methodDB
    ) {
      toast.error("All fields are required.");
      setLoading(false);
      return;
    }

    let framesAsInteger = parseInt(frames, 10);
    if (isNaN(framesAsInteger)) {
      console.error("Invalid frames value, setting it to 0");
      framesAsInteger = 0;
    }

    const imageToSend =
      selectedTemplate === "Template1" && uploadedImages.Template1
        ? uploadedImages.Template1
        : selectedTemplate === "Template2" && uploadedImages.Template2
          ? uploadedImages.Template2
          : "";

    const payload = {
      product: product,
      audience: audience,
      key_messages: keymessages,
      call_to_actions: calltoaction,
      primary_keywords: primarykeywords,
      claims: claims,
      frames: framesAsInteger,
      instruction: instructions,
      methods: methodDB,
      image: imageToSend,
      template: selectedTemplate,
    };

    try {
      if (methodDB === "Select File" && uploadedFiles.length > 0) {
        const formData = new FormData();
        uploadedFiles.forEach((fileObj) => {
          formData.append("files", fileObj.file);
        });

        const fileUploadResponse =
          await documentStructureDynamicBannerFileUpload(formData, userId);

        if (!fileUploadResponse || !fileUploadResponse.collection_name) {
          throw new Error(
            "Failed to upload files or retrieve collection_name."
          );
        }

        payload.collection_name = fileUploadResponse.collection_name;
      }

      const response = await documentStructureDynamicBannerfields(
        payload,
        userId
      );

      if (!response) {
        throw new Error("Failed to upload DynamicBannerfields.");
      }

      setStructureHeading(response);

      navigate("/documentEditor");
    } catch (error) {
      console.error("Error submitting form:", error);
      if (error.response && error.response.data) {
        console.error("API Error Response:", error.response.data);
      }
      setLoading(false);
    }
  };

  const handleSubmitArticle = async () => {
    setLoading(true);

    const created_by = sessionStorage.getItem("userID");

    if (!created_by) {
      toast.error("User ID not found in session storage.");
      return;
    }

    if (!title || !tone || !keyTopics || !method) {
      toast.error("All fields are required.");
      setLoading(false);
      return;
    }

    try {
      const payload = {
        title: title,
        tone: tone,
        key_topics: keyTopics.split(",").map((topic) => topic.trim()),
        methods: method,
        created_by: created_by,
      };

      const response = await documentStructureArticlefields(payload);

      setStructureArticle(response);

      if (!response) {
        throw new Error("Failed to upload Articlefields.");
      }

      const { created_by: responseCreatedBy, content_id } = response;

      if (method === "Q&A" && uploadedFiles.length > 0) {
        const formData = new FormData();

        uploadedFiles.forEach((fileObj) => {
          formData.append("files", fileObj.file);
        });

        const responsefromfile = await documentStructureArticleFileUpload(
          formData,
          responseCreatedBy,
          content_id
        );

        if (!responsefromfile) {
          throw new Error("Failed to upload files.");
        }
      }

      navigate("/documentHeading");
    } catch (error) {
      console.error("Error uploading files or metadata:", error);
      alert("Error uploading files or metadata. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content-structure-page">
      {loading && (
        <div className="loading-heading-container">
          <div className="loading-heading-box">
            <img
              src={AnimationDocument}
              alt="Loading animation"
              className="loading-heading-animation"
            />
            <div className="loading-heading-div">
              <span className="loading-heading-text">
                Processing, Please wait
              </span>
              <img
                src={PreloaderDocument}
                alt="Loading dots"
                className="loading-heading-dots"
              />
            </div>
          </div>
        </div>
      )}
      {contentType === "Dynamic Banner" && loading && (
        <div className="loading-heading-container">
          <div className="loading-heading-box">
            <img
              src={AnimationDynamicBanner}
              alt="Loading animation"
              className="loading-heading-animation"
            />
            <div className="loading-heading-div">
              <span className="loading-heading-text">
                Processing, Please wait
              </span>
              <img
                src={PreloaderDocument}
                alt="Loading dots"
                className="loading-heading-dots"
              />
            </div>
          </div>
        </div>
      )}
      {contentType === "Journal Paper" && loading && (
        <div className="loading-heading-container">
          <div className="loading-heading-box">
            <img
              src={AnimationFileUpload}
              alt="Loading animation"
              className="loading-heading-animation"
            />
            <div className="loading-heading-div">
              <span className="loading-heading-text">
                Processing, Please wait
              </span>
              <img
                src={PreloaderDocument}
                alt="Loading dots"
                className="loading-heading-dots"
              />
            </div>
          </div>
        </div>
      )}
      <div className={`top-navigation-grd ${loading ? "dim-content" : ""}`}>
        <span
          type="button"
          className="back-button"
          onClick={() => {
            setContentID("");
            setStructureArticle("");
            navigate("/documentGeneration");
          }}
        >
          ← Back
        </span>
      </div>

      {contentType === "Article" && (
        <div
          className={`content-structure-layout ${loading ? "dim-content" : ""}`}
        >
          <div className="form-container">
            <form className="form">
              <label className="labelinputfields" htmlFor="title">
                Title:
              </label>
              <input
                id="title"
                type="text"
                value={title}
                className="structure-input"
                onChange={(e) => setTitle(e.target.value)}
              />

              <label className="labelinputfields" htmlFor="tone">
                Tone:
              </label>
              <select
                id="tone"
                value={tone}
                className="structure-input"
                onChange={(e) => setTone(e.target.value)}
              >
                <option value="">Select Tone</option>
                <option value="Formal">Formal</option>
                <option value="Informal">Informal</option>
                <option value="Friendly">Friendly</option>
                <option value="Optimistic">Optimistic</option>
                <option value="Entertaining">Entertaining</option>
                <option value="Surprising">Surprising</option>
                <option value="Intense">Intense</option>
              </select>

              <label className="labelinputfields" htmlFor="keyTopics">
                Key Topics:
              </label>
              <input
                id="keyTopics"
                type="text"
                value={keyTopics}
                className="structure-input"
                onChange={(e) => setKeyTopics(e.target.value)}
              />

              <label className="labelinputfields" htmlFor="method">
                Method:
              </label>
              <select
                id="method"
                value={method}
                className="structure-input"
                onChange={(e) => setMethod(e.target.value)}
              >
                <option value="">Select Method</option>
                <option value="AI Assistant">AI Assistant</option>
                <option value="Web Search">Web Search</option>
                <option value="Q&A">Internal Document(s)</option>
              </select>

              {method === "Q&A" && (
                <>
                  <label className="labelinputfields" htmlFor="fileUpload">
                    Upload Files:
                  </label>
                  <label className="browse-button" htmlFor="fileUpload">
                    <GrDocumentUpload className="browse-icon" /> Browse File
                    <input
                      id="fileUpload"
                      type="file"
                      multiple
                      accept=".pdf, .doc, .docx, .ppt, .pptx"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </label>
                </>
              )}
            </form>
          </div>

          <div className="uploaded-files-container">
            {method === "Q&A" && uploadedFiles.length > 0 && (
              <div className="uploaded-files">
                <h5>Uploaded File(s)</h5>{" "}
                <ul className="uploaded-files-list">
                  {uploadedFiles.map((file, index) => (
                    <li key={index} className="file-item">
                      <div className="file-row">
                        <span className="file-icon">
                          <FaFileImage />
                        </span>
                        <div className="file-content">
                          <div className="file-header">
                            <span className="file-name">{file.name}</span>
                            <span className="file-size">{file.size}</span>
                          </div>
                          <div className="status-line"></div>
                          <span className="status-text">{file.status}</span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="form-actionsStructurebutton">
            <button
              type="button"
              className="cancel-button"
              onClick={() => navigate("/documentGeneration")}
            >
              Cancel
            </button>

            <button
              className="proceed-button"
              type="button"
              onClick={handleSubmitArticle}
            >
              Proceed
            </button>
          </div>
        </div>
      )}

      {contentType === "Dynamic Banner" && (
        <div
          className={`content-structure-layout ${loading ? "dim-content" : ""}`}
        >
          <div className="form-container">
            <form className="form">
              <label className="labelinputfields" htmlFor="product">
                Product:
              </label>
              <input
                id="product"
                type="text"
                value={product}
                className="structure-input-DB"
                onChange={(e) => setProduct(e.target.value)}
              />

              <label className="labelinputfields" htmlFor="audience">
                Audience:
              </label>
              <input
                id="audience"
                value={audience}
                className="structure-input-DB"
                onChange={(e) => setAudience(e.target.value)}
              />

              <label className="labelinputfields" htmlFor="keymessages">
                Key Messages:
              </label>
              <input
                id="keymessages"
                type="text"
                value={keymessages}
                className="structure-input-DB"
                onChange={(e) => setKeymessages(e.target.value)}
              />

              <label className="labelinputfields" htmlFor="calltoaction">
                Call to Actions:
              </label>
              <input
                id="calltoaction"
                type="text"
                value={calltoaction}
                className="structure-input-DB"
                onChange={(e) => setCalltoaction(e.target.value)}
              />

              <label className="labelinputfields" htmlFor="primarykeywords">
                Primary Keywords:
              </label>
              <input
                id="primarykeywords"
                type="text"
                value={primarykeywords}
                className="structure-input-DB"
                onChange={(e) => setPrimarykeywords(e.target.value)}
              />

              <label className="labelinputfields" htmlFor="claims">
                Claims:
              </label>
              <input
                id="claims"
                type="text"
                value={claims}
                className="structure-input-DB"
                onChange={(e) => setClaims(e.target.value)}
              />

              <label className="labelinputfields" htmlFor="frames">
                Frames:
              </label>
              <input
                id="frames"
                type="text"
                value={frames}
                className="structure-input-DB"
                onChange={(e) => setFrames(e.target.value)}
              />

              <label className="labelinputfields" htmlFor="instructions">
                Instructions:
              </label>
              <input
                id="instructions"
                type="text"
                value={instructions}
                className="structure-input-DB"
                onChange={(e) => setInstructions(e.target.value)}
              />

              <label className="labelinputfields" htmlFor="methodDB">
                Methods:
              </label>
              <select
                id="methodDB"
                value={methodDB}
                className="structure-input-DB"
                onChange={(e) => setMethodDB(e.target.value)}
              >
                <option value="">Select Method</option>
                <option value="AI Assistant">AI Assistant</option>
                <option value="Select File">Internal Documents</option>
              </select>

              {methodDB === "Select File" && (
                <>
                  <label className="labelinputfields" htmlFor="fileUpload">
                    Upload Files:
                  </label>
                  <label className="browse-button-DB" htmlFor="fileUpload">
                    <GrDocumentUpload className="browse-icon" /> Browse File
                    <input
                      id="fileUpload"
                      type="file"
                      multiple
                      accept=".pdf, .doc, .docx, .ppt, .pptx"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </label>
                </>
              )}

              <label htmlFor="template">Template:</label>
              <div className="template-container">
                <div className="template-wrapper">
                  <img
                    src={
                      selectedTemplate === "Default"
                        ? HighlightTemplate0
                        : Template0
                    }
                    alt="Template 0"
                    className="template-image"
                    onClick={() => handleTemplateClick("Default")}
                  />
                </div>

                <div className="template-wrapper">
                  <img
                    src={
                      selectedTemplate === "Template1"
                        ? HighlightTemplate1
                        : Template1
                    }
                    alt="Template 1"
                    className="template-image"
                    onClick={() => handleTemplateClick("Template1")}
                  />
                  {selectedTemplate === "Template1" && (
                    <>
                      <div className="upload-image-container">
                        <label className="upload-text">
                          Upload image
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload("Template1", e)}
                            className="hidden-input"
                          />
                        </label>
                      </div>

                      {uploadedImages.Template1 && (
                        <div className="uploaded-preview">
                          <img
                            src={uploadedImages.Template1}
                            alt="Uploaded Preview"
                          />
                          <button
                            type="button"
                            className="close-button"
                            onClick={() => handleRemoveImage("Template1")}
                          >
                            ✕
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>

                {/* <div className="template-wrapper">
                  <img
                    src={
                      selectedTemplate === "Template2"
                        ? HighlightTemplate2
                        : Template2
                    }
                    alt="Template 2"
                    className="template-image"
                    onClick={() => handleTemplateClick("Template2")}
                  />
                  {selectedTemplate === "Template2" && (
                    <>
                      <div className="upload-image-container">
                        <label className="upload-text">
                          Upload image
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload("Template2", e)}
                            className="hidden-input"
                          />
                        </label>
                      </div>

                      {uploadedImages.Template2 && (
                        <div className="uploaded-preview">
                          <img
                            src={uploadedImages.Template2}
                            alt="Uploaded Preview"
                          />
                          <button
                            type="button"
                            className="close-button"
                            onClick={() => handleRemoveImage("Template2")}
                          >
                            ✕
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div> */}
              </div>
            </form>
          </div>
          <div className="uploaded-files-container">
            {methodDB === "Select File" && uploadedFiles.length > 0 && (
              <div className="uploaded-files">
                <h5>Uploaded File(s)</h5>{" "}
                <ul className="uploaded-files-list">
                  {uploadedFiles.map((file, index) => (
                    <li key={index} className="file-item">
                      <div className="file-row">
                        <span className="file-icon">
                          <FaFileImage />
                        </span>
                        <div className="file-content">
                          <div className="file-header">
                            <span className="file-name">{file.name}</span>
                            <span className="file-size">{file.size}</span>
                          </div>
                          <div className="status-line"></div>
                          <span className="status-text">{file.status}</span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="form-actionsStructurebutton">
            <button
              type="button"
              className="cancel-button"
              onClick={() => navigate("/documentGeneration")}
            >
              Cancel
            </button>

            <button
              className="proceed-button"
              type="button"
              onClick={handleSubmitDynamicBanner}
            >
              Proceed
            </button>
          </div>
        </div>
      )}

      {contentType === "Email" && (
        <div
          className={`content-structure-layout ${loading ? "dim-content" : ""}`}
        >
          <div className="form-container">
            <form className="form">
              <label className="labelinputfields" htmlFor="product">
                Product:
              </label>
              <input
                id="product"
                type="text"
                value={product}
                className="structure-input-DB"
                onChange={(e) => setProduct(e.target.value)}
              />

              <label className="labelinputfields" htmlFor="indication">
                Indication:
              </label>
              <input
                id="indication"
                value={indication}
                className="structure-input-DB"
                onChange={(e) => setIndication(e.target.value)}
              />

              <label className="labelinputfields" htmlFor="keymessages">
                Key Messages:
              </label>
              <input
                id="keymessages"
                type="text"
                value={keymessages}
                className="structure-input-DB"
                onChange={(e) => setKeymessages(e.target.value)}
              />

              <label className="labelinputfields" htmlFor="calltoaction">
                Call to Action:
              </label>
              <input
                id="calltoaction"
                type="text"
                value={calltoaction}
                className="structure-input-DB"
                onChange={(e) => setCalltoaction(e.target.value)}
              />

              <label className="labelinputfields" htmlFor="primarykeywords">
                Primary Keywords:
              </label>
              <input
                id="primarykeywords"
                type="text"
                value={primarykeywords}
                className="structure-input-DB"
                onChange={(e) => setPrimarykeywords(e.target.value)}
              />

              <label className="labelinputfields" htmlFor="therapeuticArea">
                Therapeutic Area:
              </label>
              <select
                id="therapeuticArea"
                value={therapeuticArea}
                className="structure-input-DB"
                onChange={(e) => setTherapeuticArea(e.target.value)}
              >
                <option value="">Select Therapeutic Area</option>
                <option value="Cardiovascular">Cardiovascular</option>
                <option value="Cancer">Cancer</option>
                <option value="Diabetes">Diabetes</option>
                <option value="HIV and AIDS">HIV and AIDS</option>
                <option value="Immune-system Disease">
                  Immune-system Disease
                </option>
                <option value="Neurodegenerative Disease">
                  Neurodegenerative Disease
                </option>
                <option value="Viral Disease">Viral Disease</option>
                <option value="Rare Disease">Rare Disease</option>
                <option value="Rheumatology">Rheumatology</option>
                <option value="Immunology">Immunology</option>
                <option value="Neuroscience">Neuroscience</option>
              </select>

              <label className="labelinputfields" htmlFor="methodDB">
                Methods:
              </label>
              <select
                id="methodDB"
                value={methodDB}
                className="structure-input-DB"
                onChange={(e) => setMethodDB(e.target.value)}
              >
                <option value="">Select Method</option>
                <option value="AI Assistant">AI Assistant</option>
                <option value="Select File">Upload Document(s)</option>
              </select>

              {methodDB === "Select File" && (
                <>
                  <label className="labelinputfields" htmlFor="fileUpload">
                    Upload Files:
                  </label>
                  <label className="browse-button-DB" htmlFor="fileUpload">
                    <GrDocumentUpload className="browse-icon" /> Browse File
                    <input
                      id="fileUpload"
                      type="file"
                      multiple
                      accept=".pdf, .doc, .docx, .ppt, .pptx"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </label>
                </>
              )}
            </form>
          </div>
          <div className="uploaded-files-container">
            {methodDB === "Select File" && uploadedFiles.length > 0 && (
              <div className="uploaded-files">
                <h5>Uploaded File(s)</h5>{" "}
                <ul className="uploaded-files-list">
                  {uploadedFiles.map((file, index) => (
                    <li key={index} className="file-item">
                      <div className="file-row">
                        <span className="file-icon">
                          <FaFileImage />
                        </span>
                        <div className="file-content">
                          <div className="file-header">
                            <span className="file-name">{file.name}</span>
                            <span className="file-size">{file.size}</span>
                          </div>
                          <div className="status-line"></div>
                          <span className="status-text">{file.status}</span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="form-actionsStructurebutton">
            <button
              type="button"
              className="cancel-button"
              onClick={() => navigate("/documentGeneration")}
            >
              Cancel
            </button>

            <button
              className="proceed-button"
              type="button"
              onClick={handleSubmitRepTriggerEmail}
            >
              Proceed
            </button>
          </div>
        </div>
      )}

      {contentType === "Journal Paper" && (
        <div className={`Doc-container ${loading ? "dim-content" : ""}`}>
          <div className="file-upload-section">
            <div className="upload-item">
              <div className="upload-header">
                <label className="labelDocinputfields">Upload Template:</label>
                <label className="browse-button-Doc">
                  + Browse file
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx, .txt"
                    multiple
                    onChange={(e) => handleFileUpload(e, "Template")}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
              <div className="file-info">
                {uploadedTemplate.map((file, index) => (
                  <div key={index} className="file-details">
                    <span className="file-nameJP">{file.name}</span>
                    <MdContentCopy
                      onClick={() => handleCopy([file])}
                      className="copy-icon"
                    />
                    <button
                      className="action-button"
                      onClick={() => handleDeleteFile("Template", file.name)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="upload-item">
              <div className="upload-header">
                <label className="labelDocinputfields">Upload Source:</label>
                <label className="browse-button-Doc">
                  + Browse file
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx, .txt"
                    multiple
                    onChange={(e) => handleFileUpload(e, "Source")}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
              <div className="file-info">
                {uploadedSource.map((file, index) => (
                  <div key={index} className="file-details">
                    <span className="file-nameJP">{file.name}</span>
                    <MdContentCopy
                      onClick={() => handleCopy([file])}
                      className="copy-icon"
                    />
                    <button
                      className="action-button"
                      onClick={() => handleDeleteFile("Source", file.name)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="preview-section">
            <div className="tabs">
              <button
                className={`tab ${activeTab === "Template" ? "active" : ""}`}
                onClick={() => setActiveTab("Template")}
              >
                Template
              </button>
              <button
                className={`tab ${activeTab === "Source" ? "active" : ""}`}
                onClick={() => setActiveTab("Source")}
              >
                Source
              </button>
            </div>
            <div className="tab-content-Doc">
              {activeTab === "Template" && uploadedTemplate.length > 0 ? (
                uploadedTemplate.map((file, index) => (
                  <iframe
                    key={index}
                    src={file.fileUrl}
                    title={`Template Preview ${index}`}
                    className="file-preview"
                  ></iframe>
                ))
              ) : activeTab === "Source" && uploadedSource.length > 0 ? (
                uploadedSource.map((file, index) => (
                  <iframe
                    key={index}
                    src={file.fileUrl}
                    title={`Source Preview ${index}`}
                    className="file-preview"
                  ></iframe>
                ))
              ) : (
                <p className="placeholder">No {activeTab} Uploaded</p>
              )}
            </div>
          </div>

          <div className="form-actionsStructurebutton">
            <button
              type="button"
              className="cancel-button"
              onClick={() => navigate("/documentGeneration")}
            >
              Cancel
            </button>
            <button
              className="proceed-button"
              type="button"
              onClick={handleSubmitDocument}
            >
              Extract
            </button>
          </div>
        </div>
      )}

      {contentType === "Protocol Document" && (
        <div className={`Doc-container ${loading ? "dim-content" : ""}`}>
          <div className="file-upload-section">
            <div className="upload-item">
              <div className="upload-header">
                <label className="labelDocinputfields">Upload Template:</label>
                <label className="browse-button-Doc">
                  + Browse file
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx, .txt"
                    multiple
                    onChange={(e) => handleFileUpload(e, "Template")}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
              <div className="file-info">
                {uploadedTemplate.map((file, index) => (
                  <div key={index} className="file-details">
                    <span className="file-nameJP">{file.name}</span>
                    <MdContentCopy
                      onClick={() => handleCopy([file])}
                      className="copy-icon"
                    />
                    <button
                      className="action-button"
                      onClick={() => handleDeleteFile("Template", file.name)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="upload-item">
              <div className="upload-header">
                <label className="labelDocinputfields">Upload Source:</label>
                <label className="browse-button-Doc">
                  + Browse file
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx, .txt"
                    multiple
                    onChange={(e) => handleFileUpload(e, "Source")}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
              <div className="file-info">
                {uploadedSource.map((file, index) => (
                  <div key={index} className="file-details">
                    <span className="file-nameJP">{file.name}</span>
                    <MdContentCopy
                      onClick={() => handleCopy([file])}
                      className="copy-icon"
                    />
                    <button
                      className="action-button"
                      onClick={() => handleDeleteFile("Source", file.name)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="preview-section">
            <div className="tabs">
              <button
                className={`tab ${activeTab === "Template" ? "active" : ""}`}
                onClick={() => setActiveTab("Template")}
              >
                Template
              </button>
              <button
                className={`tab ${activeTab === "Source" ? "active" : ""}`}
                onClick={() => setActiveTab("Source")}
              >
                Source
              </button>
            </div>
            <div className="tab-content-Doc">
              {activeTab === "Template" && uploadedTemplate.length > 0 ? (
                uploadedTemplate.map((file, index) => (
                  <iframe
                    key={index}
                    src={file.fileUrl}
                    title={`Template Preview ${index}`}
                    className="file-preview"
                  ></iframe>
                ))
              ) : activeTab === "Source" && uploadedSource.length > 0 ? (
                uploadedSource.map((file, index) => (
                  <iframe
                    key={index}
                    src={file.fileUrl}
                    title={`Source Preview ${index}`}
                    className="file-preview"
                  ></iframe>
                ))
              ) : (
                <p className="placeholder">No {activeTab} Uploaded</p>
              )}
            </div>
          </div>

          <div className="form-actionsStructurebutton">
            <button
              type="button"
              className="cancel-button"
              onClick={() => navigate("/documentGeneration")}
            >
              Cancel
            </button>
            <button
              className="proceed-button"
              type="button"
              onClick={handleSubmitDocument}
            >
              Extract
            </button>
          </div>
        </div>
      )}

      {contentType === "GRD" && (
        <div className={`Doc-container ${loading ? "dim-content" : ""}`}>
          <div className="file-upload-section">
            <div className="contentType-Grd" style={{marginBottom: '2vh'}}>
              <h5>Content Structure</h5>
            </div>
            <div className="contentType-Grd" style={{marginBottom: '5vh'}}>
              <p>Content Generation &gt; {contentType}</p>
            </div>
            <div className="upload-item">
              <div className="upload-header">
                <label className="labelDocinputfields">Upload Template:</label>
                <label className="browse-button-Doc">
                  + Browse file
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx, .txt"
                    multiple
                    onChange={(e) => handleFileUpload(e, "Template")}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
              <div className="file-info">
                {uploadedTemplate.map((file, index) => (
                  <div key={index} className="file-details">
                    <span className="file-nameJP">{file.name}</span>
                    <MdContentCopy
                      onClick={() => handleCopy([file])}
                      className="copy-icon"
                    />
                    <button
                      className="action-button"
                      onClick={() => handleDeleteFile("Template", file.name)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="upload-item">
              <div className="upload-header">
                <label className="labelDocinputfields">Upload Source:</label>
                <label className="browse-button-Doc">
                  + Browse file
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx, .txt"
                    multiple
                    onChange={(e) => handleFileUpload(e, "Source")}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
              <div className="file-info">
                {uploadedSource.map((file, index) => (
                  <div key={index} className="file-details">
                    <span className="file-nameJP">{file.name}</span>
                    <MdContentCopy
                      onClick={() => handleCopy([file])}
                      className="copy-icon"
                    />
                    <button
                      className="action-button"
                      onClick={() => handleDeleteFile("Source", file.name)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="upload-item">
              <div className="upload-header">
                <label className="labelDocinputfields">Select Table:</label>
                <select
                  className="browse-button-Doc"
                  value={selectedOption}
                  onChange={(e) => setSelectedOption(e.target.value)}
                >
                  <option>Select Table</option>
                  {tableOptions.map((table, index) => (
                    <option key={index} value={table}>
                      {table}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="preview-section">
            <div className="tabs">
              <button
                className={`tab ${activeTab === "Template" ? "active" : ""}`}
                onClick={() => setActiveTab("Template")}
              >
                Template
              </button>
              <button
                className={`tab ${activeTab === "Source" ? "active" : ""}`}
                onClick={() => setActiveTab("Source")}
              >
                Source
              </button>
            </div>
            <div className="tab-content-Doc">
              {activeTab === "Template" && uploadedTemplate.length > 0 ? (
                uploadedTemplate.map((file, index) => (
                  <iframe
                    key={index}
                    src={file.fileUrl}
                    title={`Template Preview ${index}`}
                    className="file-preview"
                  ></iframe>
                ))
              ) : activeTab === "Source" && uploadedSource.length > 0 ? (
                uploadedSource.map((file, index) => (
                  <iframe
                    key={index}
                    src={file.fileUrl}
                    title={`Source Preview ${index}`}
                    className="file-preview"
                  ></iframe>
                ))
              ) : (
                <p className="placeholder">No {activeTab} Uploaded</p>
              )}
            </div>
          </div>

          <div className="form-actionsStructurebutton">
            <button
              type="button"
              className="cancel-button"
              onClick={() => navigate("/documentGeneration")}
            >
              Cancel
            </button>
            <button
              className="proceed-button"
              type="button"
              onClick={handleSubmitDocument}
            >
              Extract
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentStructure;

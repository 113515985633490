import React, { useContext, useState } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Button,
  InputLabel,
} from "@mui/material";
import { MyContext } from "../../context/ContextApi";
import { translateColumnDataSentiData } from "../../api/Postaction";
import { toast } from "react-toastify";
import { Table } from "rsuite";

const { Column, HeaderCell, Cell } = Table;

function MedLingoSenti() {
  const {
    sentiStoredFileName,
    sentiTableHeading,
    setSentiMedPulse,
    setSentiMedLingoColumnValue,
    setSentiMedLingoFileName,
  } = useContext(MyContext);

  const [tableData, setTableData] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState();

  const fetch = async () => {
    let a = toast.warning("Fetching Tabledata", { autoClose: false });
    let body = {
      column_name: selectedColumn,
      uploaded_file_name: sentiStoredFileName,
    };

    try {
      let res = await translateColumnDataSentiData(body);
      console.log(res, "res");
      setTableData(res?.data?.lingo_output);
      setSentiMedLingoColumnValue(res?.data?.column_name);
      setSentiMedLingoFileName(res?.data?.stored_file);
      if (res?.data?.lingo_output.length > 0) {
        toast.dismiss(a);
        toast.success("Fetched successfully");
        setSentiMedPulse(true);
      }
    } catch (err) {
      toast.dismiss(a);
      toast.error("Error while fetching table data");
      console.log(err);
    } finally {
      toast.dismiss(a);
    }
  };

  const handleChange = (e) => {
    setSelectedColumn(e.target.value);
  };

  const translateColumn = () => {
    if (selectedColumn !== "" || selectedColumn !== null) {
      fetch();
    }
  };

  const calculateRowHeight = (rowData) => {
    const baseHeight = 40;
    const lineHeight = 20;
    const maxLength = Math.max(
      ...columns?.map((col) => rowData?.[col]?.toString()?.length || 0)
    );
    const maxLines = Math.ceil(maxLength / 30) || 1;
    return baseHeight + (maxLines - 1) * lineHeight;
  };

  const CustomCell = (props) => (
    <Cell
      {...props}
      style={{
        padding: 15,
        whiteSpace: "normal",
        wordBreak: "break-word",
        overflowWrap: "break-word",
        lineHeight: "20px",
        overflowY: "auto",
      }}
    />
  );

  const columns = Object.keys(tableData[0] || {});

  return (
    <div>
      <div style={{ display: "flex" }}>
        <FormControl style={{ minWidth: "20vw" }}>
          <InputLabel id="choose-the-value">Choose the value</InputLabel>
          <Select
            labelId="choose-the-value"
            label="choose-the-value"
            id="choose-the-value"
            value={selectedColumn}
            onChange={(e) => handleChange(e)}
            className="medLingo_select mt-1"
          >
            {/* <MenuItem value=""></MenuItem> */}
            {sentiTableHeading?.map((column, index) => (
              <MenuItem key={index} value={column}>
                {column}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          style={{ marginLeft: "40px" }}
          onClick={translateColumn}
        >
          Translate
        </Button>
      </div>
      {/* <div style={{ marginTop: "40px" }}>
        {tableData?.length > 0 ? (
          <Table
            className="custom-table"
            // virtualized
            wordWrap={false}
            hover={false}
            height={window.innerHeight - 150}
            data={tableData}
            style={{
              // scrollbarWidth: "0",
              maxHeight: "70vh",
              width: "100%",
            }}
            bordered
            rowKey={(rowData) => rowData.id}
          >
            {[...Object.keys(tableData[0])]?.map((key, index) => (
              <Column
                width={key.toLowerCase() === "description" ? 300 : 200}
                verticalAlign="middle"
                key={key}
              >
                <HeaderCell>{key}</HeaderCell>
                <Cell dataKey={key}>
                  {(rowData) => {
                    const description = rowData[key] || "";
                    const words =
                      typeof description === "string"
                        ? description.split(" ")
                        : [];
                    const displayText =
                      words?.length > 4
                        ? words?.slice(0, 4).join(" ") + "..."
                        : description;

                    return (
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          overflowX: "auto",
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                        title={description}
                      >
                        {displayText}
                      </div>
                    );
                  }}
                </Cell>
              </Column>
            ))}
          </Table>
        ) : (
          "No table data"
        )}
      </div> */}
      <div style={{ maxHeight: "550px", overflowY: "auto", marginTop:'40px' }}>
        <Table
          data={tableData}
          bordered
          cellBordered
          rowHeight={calculateRowHeight}
          height={400}
          autoHeight
          style={{
            wordWrap: "break-word",
            whiteSpace: "normal",
            overflowX: "auto",
          }}
        >
          {columns.map((column, index) => (
            <Column key={index} width={200} flexGrow={1}>
              <HeaderCell>{column}</HeaderCell>
              <CustomCell dataKey={column} />
            </Column>
          ))}
        </Table>
      </div>
    </div>
  );
}

export default MedLingoSenti;
